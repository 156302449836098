<template>
  <v-dialog
      style="z-index: 400"
      v-model="dialog"
      persistent
      width="950">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="btn-create-field"
          v-bind="attrs"
          v-on="on"
          color="secondary"
          width="90%"
          style="border-radius: 8px"
          height="50">
        <v-icon>mdi-plus</v-icon>
        Add field
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="px-4 pb-4" style="background-color: var(--v-block-base)">
        <v-layout justify-center align-center style="height: 90px">
          <v-btn icon small @click="closeDialog" style="position: absolute; top: 10px; right: 10px">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span style="font-size: 30px; color: #fafafa">Select the field adding option</span>
        </v-layout>
        <v-layout style="min-height: 150px" align-center>
          <v-stepper v-model="step" width="100%" class="elevation-0" style="background-color: transparent">
            <v-stepper-items>
              <v-stepper-content
                  class="pa-0"
                  key="step1-content"
                  step="1"
              >
                <v-layout class="flex-wrap" justify-center>
                  <div @click="nextStep('farm')" style="width: 210px; height: 220px; cursor: pointer" class="mx-2 py-2">
                    <v-layout class="fill-height" style="border-radius: 8px; background-color: var(--v-primary-base)"
                              column>
                      <div style="height: 40px; width: 100%; flex: none; text-align: center" class="pa-2">
                        <v-layout class="fill-height" align-center justify-center>
                          <h4 class="text-uppercase">Import from Survey</h4>
                        </v-layout>
                      </div>
                      <v-layout
                          class="fill-height"
                          align-center
                          justify-center
                          style="background-color: var(--v-tab-base); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px">
                        <div
                            style="font-weight: bold; font-size: 36px; color: #002f6c; text-transform: capitalize; transform: scale(1, 0.9); text-shadow: 1px 1px #003300;">
                          <span style="color: #058c0b">EOF-</span>Survey
                        </div>
                      </v-layout>
                    </v-layout>
                  </div>
                  <div @click="nextStep('file')" style="width: 210px; height: 220px; cursor: pointer" class="mx-2 py-2">
                    <v-layout class="fill-height" style="border-radius: 8px; background-color: var(--v-primary-base)"
                              column>
                      <div style="height: 40px; width: 100%; flex: none; text-align: center" class="pa-2">
                        <v-layout class="fill-height" align-center justify-center>
                          <h4 class="text-uppercase">Upload field</h4>
                        </v-layout>
                      </div>
                      <v-layout class="fill-height" align-center justify-center column
                                style="background-color: var(--v-tab-base); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px">
                        <v-icon size="90">mdi-script-text-outline</v-icon>
                        <span>*.GEOJSON</span>
                      </v-layout>
                    </v-layout>
                  </div>
                  <div @click="nextStep('draw')" style="width: 210px; height: 220px; cursor: pointer" class="mx-2 py-2">
                    <v-layout class="fill-height" style="border-radius: 8px; background-color: var(--v-primary-base)"
                              column>
                      <div style="height: 40px; width: 100%; flex: none; text-align: center" class="pa-2">
                        <v-layout class="fill-height" align-center justify-center>
                          <h4 class="text-uppercase">Draw field on map</h4>
                        </v-layout>
                      </div>
                      <v-layout class="fill-height" align-center justify-center column
                                style="background-color: var(--v-tab-base); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px">
                        <v-icon size="90">mdi-shape-polygon-plus</v-icon>
                        <span></span>
                      </v-layout>
                    </v-layout>
                  </div>
                  <div @click="nextStep('detect')" style="width: 210px; height: 220px; cursor: pointer" class="mx-2 py-2">
                    <v-layout class="fill-height" style="border-radius: 8px; background-color: var(--v-primary-base)"
                              column>
                      <div style="height: 40px; width: 100%; flex: none; text-align: center" class="pa-2">
                        <v-layout class="fill-height" align-center justify-center>
                          <h4 class="text-uppercase">Auto detection</h4>
                        </v-layout>
                      </div>
                      <v-layout class="fill-height" align-center justify-center column
                                style="background-color: var(--v-tab-base); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px">
                        <v-icon size="90">mdi-selection-search</v-icon>
                        <span></span>
                      </v-layout>
                    </v-layout>
                  </div>
                </v-layout>
                <div class="mt-5" style="font-size: 15px">
                  <b>Custom upload.</b> If you wish to upload files with all the additional field parameters, <b><a
                    style="color: var(--v-secondary-base)">contact us</a></b> Custom upload is only available in
                  Essential and Professional plans.
                </div>
              </v-stepper-content>
            </v-stepper-items>
            <v-stepper-items>
              <v-stepper-content
                  class="pa-0"
                  key="step2-content"
                  step="2"
              >
                <v-layout style="min-height: 150px; max-height: 450px;" column>
                  <v-layout class="fill-height" column>
                    <v-layout class="flex-wrap py-2" v-if="type === 'farm'">
                      <v-spacer/>
                      <div style="width: 250px">
                        <v-text-field
                            v-model="recordFilter.search"
                            @input="debounceInput"
                            solo-inverted
                            hide-details
                            dense
                            label="Search"
                            append-icon="mdi-magnify"/>
                      </div>
                    </v-layout>
                    <v-data-table
                        v-model="selectedFarm"
                        single-select
                        @click:row="rowClick"
                        v-if="type === 'farm'"
                        height="280"
                        fixed-header
                        :headers="headers"
                        :items="reports"
                        :search="recordFilter.search"
                        :loading="loading"
                        :items-per-page="10"
                        class="row-pointer order-table d-flex flex-column"
                        hide-default-footer
                        :server-items-length.sync="totalItem"
                        loading-text="Loading... Please wait"
                        :options.sync="options"
                        :page.sync="page"
                    >
                      <template v-slot:[`item.preview`]="{ item }">
                        <div style="width: 60px; height: 60px">
                          <MapPreview :geojson="item.featureCollection" :id="'farm_' + item.id"/>
                        </div>
                      </template>
                    </v-data-table>
                    <div class="text-center" style="flex: none; height: auto" v-if="type === 'farm'">
                      <v-pagination :total-visible="5" v-model="page" :length="pageCount"></v-pagination>
                    </div>
                    <UploadField v-if="type === 'file'" :currentFile.sync="file"/>
                  </v-layout>
                  <v-layout style="height: 50px" class="py-2">
                    <v-btn color="button" width="90" @click="backStep">back</v-btn>
                    <v-spacer/>
                    <v-btn color="secondary" width="90" @click="submit">Submit</v-btn>
                  </v-layout>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-layout>
      </v-card-text>
      <SaveField ref="saveField" @saveField="(data) => $emit('saveField', data)"/>
    </v-card>
  </v-dialog>
</template>

<script>
import {debounce} from "lodash"
import {
  getRecords
} from "@/backend"
import UploadField from "@/components/home/dashboard/UploadField";
import MapPreview from "@/components/MapPreview";
import SaveField from "@/components/home/dashboard/FieldControl/SaveField";
import sleep from "@/ultis/sleep";

export default {
  name: "AddField",
  components: {SaveField, MapPreview, UploadField},
  data() {
    return {
      firstLoad: true,
      dialog: false,
      selectedFarm: undefined,
      file: undefined,
      step: 1,
      type: undefined,
      loading: false,
      headers: [
        {text: "Preview", value: "preview", sortable: false, align: "start"},
        {text: "Survey name", value: "survey", sortable: true, align: "start"},
      ],
      totalItem: 100,
      pageCount: 0,
      recordFilter: {
        search: undefined,
      },
      reports: [],
      page: 1,
      options: {},
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.file = undefined
        this.step = 1
      }
      this.selectedFarm = undefined
    },
    options: {
      handler() {
        this.getRecord()
      },
      deep: true
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    nextStep(type) {
      this.type = type
      this.step = 2
      switch (type) {
        case 'draw':
          this.$emit('openDrawTool')
          this.closeDialog()
          break
        case 'detect':
          this.$emit('openDetectTool')
          this.closeDialog()
          break
        case 'farm':
          if (!this.firstLoad) this.getRecord()
          break
      }
    },
    backStep() {
      this.step = 1
      this.resetData()
    },
    submit() {
      switch (this.type) {
        case 'file':
          if (!this.file || !!this.file[0].error) return
          for (let index = 0; index < this.file[0].geometry.features.length; index++) {
            this.$emit('saveField', {
              name: this.file[0].fileName + '_' + index,
              geometry: this.file[0].geometry.features[index].geometry,
              isSelectField: true
            })
          }
          this.dialog = false
          break
        case 'farm':
          this.$refs.saveField.openSaveForm(this.selectedFarm[0].featureCollection, this.selectedFarm[0])
          this.dialog = false
          break
      }
    },
    resetData() {
      this.reports = []
      this.recordFilter.search = ''
      this.page = 1
      this.selectedFarm = undefined
      this.file = undefined
    },
    rowClick: function (item, row) {
      row.select(true)
      this.selectedFarm = [item]
    },
    getParamRecord() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = '-' + this.options.sortBy[0]
        else sort = this.options.sortBy[0]
      }
      let param = JSON.parse(JSON.stringify(this.recordFilter))
      param['per_page'] = 5
      param['page'] = this.options.page
      param['sort'] = sort
      return param
    },
    debounceInput: debounce(function (e) {
      this.getRecord()
    }, 500),
    async getRecord() {
      try {
        this.selectedFarm = undefined
        this.reports = []
        this.loading = true
        const resRecord = await getRecords(this.getParamRecord())
        this.reports = resRecord.data.data
        this.reports.forEach(field => {
          field.featureCollection = {
            "type": "FeatureCollection",
            "name": 'farm_' + field.id,
            "features": [
              {
                "properties": {"farm_id": field.id},
                "type": "Feature",
                "geometry": JSON.parse(field.geometry)
              }]
          }
        })
        this.totalItem = resRecord.data.total
        this.pageCount = resRecord.data.last_page
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    closeDialog() {
      this.type = undefined
      this.step = 1
      this.dialog = false
    }
  }
}
</script>

<style scoped>
/deep/
tr.v-data-table__selected {
  background: #777c85 !important;
}

/deep/
.v-data-table-header tr th {
  background-color: var(--v-accent-base) !important;
}
</style>

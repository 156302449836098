<template>
  <v-dialog persistent v-model="isDetectForm" width="450">
    <v-card :loading="loading">
      <v-card-text class="px-4 pb-4" style="background-color: var(--v-block-base)">
        <div style="height: 50px; flex: none; width: 100%">
          <v-layout justify-center align-center>
            <v-btn icon small @click="isDetectForm = false" style="position: absolute; top: 10px; right: 10px">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </div>
        <v-layout style="" column justify-center align-center>
          <div style="flex: none; height: 40px; width: 100%">
            <v-layout justify-center class="text-header" style="color: white">
              Auto Detect Crop
            </v-layout>
          </div>
          <div>
            We have found the crop for the current field, do you want to use the crop for this field?
          </div>
          <b style="color: var(--v-secondary-base)" class="mx-1"> -- Crop name: {{detectData}} --</b>
        </v-layout>
      </v-card-text>
      <v-card-actions class="pb-4" style="background-color: var(--v-block-base)">
        <v-spacer/>
        <v-btn :loading="loading" width="100" color="secondary" @click="submit">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {applyDetect} from "@/backend";

export default {
  name: "DetectCrop",
  data() {
    return {
      loading: false,
      id: undefined,
      isDetectForm: false,
      detectData: ''
    }
  },
  methods: {
    openDialog(detectData, id) {
      this.id = id
      this.detectData = detectData
      this.isDetectForm = true
    },
    async submit() {
      try {
        this.loading = true
        await applyDetect({
          id: this.id,
          data: {
            name: this.detectData
          }
        })
        this.$emit('refreshData', this.id)
        this.isDetectForm = false
      } catch (e) {

      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
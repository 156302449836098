<template>
  <v-menu offset-y top v-model="legendMenu" :close-on-content-click="false" style="z-index: 400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          :disabled="!legends.length"
          height="40"
          :outlined="$attrs['outlined']"
          :style="{'border': $attrs['outlined'] ? '1px solid #5B606A' : ''}"
          class="elevation-0"
          color="transparent"
          style="justify-content: start; font-size: 11px; border-radius: 0"
          :width="width ? width : ''"
          v-bind="attrs"
          v-on="on"
      >
        <v-layout align-center style="text-transform: none">
          {{ type.name ? type.name : 'Select Index' }}
          <v-spacer/>
          <v-icon class="ml-2">mdi-menu</v-icon>
        </v-layout>
      </v-btn>
    </template>
    <v-layout column style="max-height: 385px; overflow-y: auto">
      <v-list
          dense
          width="100%"
          v-for="(group, index) in legends"
          :key="index"
      >
        <v-subheader v-if="group.name">{{ group.name }}</v-subheader>
        <v-divider/>
        <v-tooltip
            max-width="350"
            :left="$attrs['left']"
            :right="$attrs['right']"
            color="#000000e8"
            v-for="(item, ind) in group.indices"
            :key="'index_' + ind">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
                class="pl-7"
                @click="changeIndex(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ item.description ? item.description : item.name }}</span>
        </v-tooltip>
        <v-layout align-center justify-center v-if="group.showMore">
          <v-btn style="font-size: 9px" width="100%" text @click="transformLegend(index)">Show more
          </v-btn>
        </v-layout>
        <v-divider/>
      </v-list>
    </v-layout>
  </v-menu>
</template>

<script>
export default {
  name: "SelectIndex",
  data() {
    return {
      legendMenu: false
    }
  },
  mounted() {
    this.transformLegend()
  },
  watch: {
    legendMenu(val) {
      if (!val) this.transformLegend()
    },
    mapLegend(val) {
      if(val.length) this.transformLegend()
    }
  },
  props: {
    width: {
      type: String,
      default: ''
    },
    type: {
      type: Object,
      default: {}
    },
    customLegend: {
      type: Array,
      default: () => []
    },
    mapLegend: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    legends:{
      get() {
        return this.customLegend
      },
      set(val) {
        this.$emit('update:customLegend', val)
      }
    }
  },
  methods: {
    changeIndex(index) {
      this.$emit('changeIndex', index)
      this.legendMenu = false
    },
    transformLegend(index) {
      let legend = []
      this.mapLegend.forEach((group, ind) => {
        if (group.indices.length > 3 && ind !== index) {
          legend.push({
            name: group.name,
            indices: group.indices.slice(0, 3),
            showMore: true
          })
        } else legend.push({
          name: group.name,
          indices: group.indices,
          showMore: false
        })
      })
      this.legends = legend
    },
  }
}
</script>

<style scoped>

</style>

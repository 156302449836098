<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden;" ref="mapBound">
    <v-layout class="fill-height">
      <div class="flex">
        <div class="map-container" ref="miniMapContainer"/>
      </div>
    </v-layout>
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import sleep from "@/ultis/sleep"
import utils from "@/ultis/genUUID"
import VectorLayer from "@/models/layer/vector/vector"
import RasterLayer from "@/models/layer/raster/raster"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {CircleMode, DirectMode, SimpleSelectMode} from "@/ultis/draw"
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawLineString from "@mapbox/mapbox-gl-draw/src/modes/draw_line_string"
import mapStyle from "@/assets/MapStyle/mapstyle.json"
import DrawTool from "@/components/DrawTool"
import SearchLocation from "@/components/SearchLocation"
import bbox from "@turf/bbox"
import area from '@turf/area'
import MeasureTool from "@/components/Measurement"
import distance from "@turf/line-distance"

let map
export default {
  name: "MiniMap",
  components: {MeasureTool, SearchLocation, DrawTool},
  data() {
    return {
      images: require('@/assets/data.json'),
      map: undefined,
      loading: false,
      mode: {},
    }
  },
  computed: {
  },
  props: {
    field: {type: Object, default: {}}
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    this.map = new window.mapboxgl.Map({
      container: this.$refs.miniMapContainer,
      style: mapStyle,
      center: [105.8466249704361, 21.003883313511878],
      zoom: 1,
      attributionControl: false
    })
    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.on("style.load", () => {
      this.reSize()
      this.map.dragPan.disable()
      this.map.scrollZoom.disable()
      this.map.doubleClickZoom.disable()
      this.addPolygonToMap(this.field.featureCollection, 'red', this.field.name)
    })
    this.map.on("move", () => {
    })
    // addEventListener('fullscreenchange', (e) => {
    //   if (!document.fullscreenElement) this.isMapFullscreen = false
    // })
  },
  methods: {
    async reSize() {
      let mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      mapCanvas.style.height = '100%'
      mapCanvas.style.width = '100%'
      await sleep(0)
      this.map.resize()
    },
    displayVector(geometry, type, color, id) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'polygon':
          this.addPolygonToMap(geometry, color, id)
          break
      }
    },
    addMarkerToMap(geometry, color) {
      new mapboxgl.Marker({color: color})
          .setLngLat(geometry.coordinates)
          .addTo(this.map)
      this.submitFlyTo(geometry.coordinates)
    },
    addPolygonToMap(geometry, color, id) {
      this.map.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      let vector = new VectorLayer({
        'id': id,
        'type': 'fill',
        'geometry': geometry,
        'color': color,
        'style': {
          'fill-color': color,
          'fill-opacity': 0.5
        }
      })
      let layer = vector.getMapboxLayer()
      this.map.addLayer(layer)
      this.submitZoom(layer.bbox)
    },
    addMultiImageToMap(layers) {
      this.removeAllImage()
      layers.forEach((layer, index) => {
        this.addImageToMap(layer.tileUrl, layer.name, !index ? 'visible' : 'none')
      })
    },
    addImageToMap(tileUrl, name, visibility) {
      // let bbox = this.layers[0].bbox
      let bbox = [103.605701, 1.15865671, 104.088417548, 1.470775]
      let raster = new RasterLayer({
        id: name,
        name: name,
        tileUrl: tileUrl,
        bbox: bbox,
        visibility: visibility
      })
      this.map.addLayer(raster.getMapboxLayer())
      this.submitZoom(bbox)
      this.imageLayers.push(raster.getMapboxLayer())
      if (visibility === 'visible') {
        this.imageSelected = raster.getMapboxLayer().id
      }
    },
    removeAllImage() {
      this.imageLayers.forEach(layer => {
        this.removeLayer(layer.id)
      })
      this.imageSelected = undefined
      this.imageLayers = []
    },
    removeLayer(layerId) {
      this.map.removeLayer(layerId)
    },
    changeVisibility(layerId) {
      this.imageLayers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
      this.map.setLayoutProperty(layerId, 'visibility', 'visible')
      this.$emit('getWeatherData', this.imageLayers.find(layer => layerId === layer.id).name)
    },
    zoomToLayer(layerId) {
      let layer = this.layers.find(val => val.id === layerId)
      if (layer) this.submitZoom(layer.bbox)
    },
    submitFlyTo(point) {
      this.map.easeTo({
        center: point,
        zoom: 15.5
      })
    },
    submitZoom(bbox) {
      this.map.fitBounds(bbox, {
        'duration': 0,
        'padding': 2
      })
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      map = undefined
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.legend {
  width: 150px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-bottom-right-radius: 8px
}

.layer-control {
  width: 150px;
  height: 110px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 8px
}

.popup-area {
  min-width: 150px;
  height: 22px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 4px
}

/deep/
.image-radio i {
  font-size: 18px;
}

/deep/
.image-radio .v-input--selection-controls__ripple {
  width: 0;
  height: 0;
}
</style>

<template>
  <div v-if="saveForm" style="width: 650px; max-height: 500px; border-radius: 6px; background-color: var(--v-primary-base)"
       class="pa-5 elevation-2">
    <v-layout class="fill-height" column>
      <v-layout justify-center>
        <span style="font-size: 16px; text-transform: uppercase; font-weight: bold"> Field info</span>
      </v-layout>
      <v-layout class="fill-height pb-2" column justify-center>
        <v-form @submit="submit" onSubmit="return false" v-model="valid" ref="form" style="width: 100%" class="pt-0">
          <v-layout column style="max-height: 390px" class="overflow-y-auto">
            <span style="font-size: 14px">Field Name:</span>
            <v-text-field
                label="Name"
                dense
                solo-inverted
                v-model="name"
                solo
                :rules="[rules.required]"
            ></v-text-field>
            <span style="font-size: 14px">Group:</span>
            <v-combobox
                dense
                solo-inverted
                v-model="group"
                :items="groups"
                label="Group"
                solo
            ></v-combobox>
            <v-btn width="100%" height="45" class="mb-4" color="secondary" @click="addNewField">Add Season</v-btn>
            <v-expansion-panels>
              <v-expansion-panel
                  v-for="(item,i) in currentField.information"
                  :key="i"
              >
                <v-expansion-panel-header>
                  <v-layout align-center>
                    {{ item.seasonYear }}
                    <v-spacer/>
                    <v-btn icon x-small color="red" @click.native.stop="currentField.information.splice(i, 1)" class="mr-2">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout align-center class="pb-2">
                    <div style="flex: 1" class="pr-1">
                      <span style="font-size: 14px">Season year:</span>
                      <YearPicker
                          @input="item.sowingDate = item.seasonYear + '-01-01'"
                          v-model="item.seasonYear"
                          label="Season year"
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="px-1">
                      <span style="font-size: 14px">Crop name:</span>
                      <v-text-field
                          hide-details
                          solo-inverted
                          dense
                          v-model="item.cropName"
                          label="Crop name"
                      >
                      </v-text-field>
                    </div>
                    <div style="flex: 1" class="pl-1">
                      <span style="font-size: 14px">Maturity:</span>
                      <v-text-field
                          hide-details
                          solo-inverted
                          dense
                          v-model="item.maturity"
                          label="Maturity"
                      ></v-text-field>
                    </div>
                  </v-layout>
                  <v-layout align-center class="py-2">
                    <div style="flex: 1" class="pr-1">
                      <span style="font-size: 14px">Sowing date:</span>
                      <DatePicker
                          @input="item.harvestingDate = item.sowingDate + '-01-01'"
                          :min="item.seasonYear +'-01-01'"
                          v-model="item.sowingDate"
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="px-1">
                      <span style="font-size: 14px">Harvesting date:</span>
                      <DatePicker
                          :min="item.sowingDate ? item.sowingDate : item.seasonYear +'-01-01'"
                          clearable
                          v-model="item.harvestingDate"
                          solo-inverted
                          dense
                          hide-details
                      />
                    </div>
                    <div style="flex: 1" class="pl-1">
                    </div>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
          <v-layout style="height: 40px" align-end class="mt-3">
            <v-btn color="button" width="100" @click="cancelEdit">Cancel</v-btn>
            <v-spacer/>
            <v-btn type="submit" color="secondary" width="100">Save</v-btn>
          </v-layout>
        </v-form>
      </v-layout>
<!--      <div style="width: 100%; height: 50px;">-->
<!--      </div>-->
    </v-layout>
  </div>
</template>

<script>
import {getGroup} from "@/backend";
import sleep from "@/ultis/sleep";
import YearPicker from "@/components/YearPicker";
import DatePicker from "@/components/DatePicker";

export default {
  name: "FieldInfo",
  components: {DatePicker, YearPicker},
  data() {
    return {
      description: undefined,
      valid: false,
      saveForm: false,
      name: undefined,
      group: undefined,
      groups: [],
      currentField: undefined,
      rules: {
        required: value => !!value || 'Name is required',
      },
    }
  },
  methods: {
    addNewField() {
      this.currentField.information.unshift({
        seasonYear: new Date().getFullYear(),
        cropName: undefined,
        maturity: undefined,
        sowingDate: new Date().getFullYear() + '-01-01'
      })
    },
    async openDialog(field) {
      this.currentField = JSON.parse(JSON.stringify(field))
      this.name = this.currentField.name
      await this.getGroups()
      this.group = this.currentField.group
      this.saveForm = true
    },
    async getGroups () {
      try {
        this.loading = true
        const res = await getGroup()
        this.groups = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    cancelEdit() {
      this.saveForm = false
      this.$emit('cancelEdit')
    },
    async submit() {
      await sleep(0)
      this.$refs.form.validate()
      if (!this.valid) return
      this.$emit('saveField',
          {
            name: this.name,
            group: this.group,
            information: (this.currentField.information && this.currentField.information.length) ? this.currentField.information.filter(val => val.cropName) : []
          }
      )
      this.saveForm = false
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-layout class="fill-height pa-2" column justify-center>
    <v-layout column>
      <div style="height: 45px; flex: none" class="mt-5">
        <v-btn :disabled="Number(zoomLevel) < 12" style="width: 100%" color="secondary"
               @click="detectField">Detection
        </v-btn>
      </div>
      <span class="pt-1" style="font-size: 11px; color: yellow">(Zoom level >= 12 | Current zoom level: {{ zoomLevel }})</span>
      <div style="height: 35px; flex: none" class="mt-4">
        <v-layout>
          Results
          <v-spacer/>
          <span style="font-size: 13px">Total item: {{ detectFields.length }}</span>
        </v-layout>
      </div>
      <v-layout style="height: 100%;" v-if="detectFields.length">
        <v-list dense width="100%" style="height: calc(100vh - 270px); overflow-y: auto">
          <v-list-item-group v-model="selectedField" mandatory>
            <v-list-item v-for="(field, index) in detectFields" :key="index" :value="field.geometry"
                         :id="field.properties.code">
              <v-layout class="fill-height py-1" align-center>
                <div style="width: 70px; height: 70px; flex: none; position: relative" class="mr-3">
                  <MapPreview :geojson="field.geometry" :id="'detect_' + index"/>
                </div>
                {{ 'Field - ' + index }}
                <v-spacer/>
<!--                <v-checkbox color="secondary" hide-details dense :value="selectedField === field.geometry"></v-checkbox>-->
              </v-layout>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-layout>
    </v-layout>
      <v-btn color="secondary" width="100%" height="50" @click="saveField" style="border-radius: 8px">Submit</v-btn>
  </v-layout>
</template>

<script>
import MapPreview from "@/components/MapPreview"
import DragCP from "@/ultis/DragComponent"
import {mapState} from "@/store/ults";

export default {
  name: "DetectTool",
  components: {MapPreview},
  data() {
    return {
      selectedField: undefined,
    }
  },
  props: {},
  computed: {
    ...mapState("map", ["zoomLevel", "detectFields", 'isDetect']),
  },
  watch: {
    selectedField(val) {
      if (val) this.$emit('addDraw', val)
    }
  },
  methods: {
    detectField() {
      this.detectFields = []
      this.$emit('deleteAll')
      this.$emit('submitDetection')
    },
    saveField() {
      this.$emit('saveField')
    },
    cancel() {
      this.$emit('deleteAll')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
      persistent
      v-model="saveForm"
      width="500">
    <v-card>
      <v-card-text class="pt-6 pb-2 px-8" style="background-color: #272c3a">
        <v-btn icon small @click="saveForm = false" style="position: absolute; top: 10px; right: 10px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-layout justify-center align-center column>
          <span style="font-size: 18px; text-transform: uppercase; font-weight: bold">Update Field</span>
          <v-form onSubmit="return false;" v-model="valid" ref="form" style="width: 100%" class="pt-4">
            <span>Field Name:</span>
            <v-text-field
                autofocus
                @focus="$event.target.select()"
                solo-inverted
                label="Name"
                placeholder="Name"
                v-model="name"
                solo
                :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-layout>
      </v-card-text>
      <v-card-actions style="background-color: #272c3a" class="pb-6 px-8">
        <v-spacer/>
        <v-btn width="100" color="secondary" @click="submit" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Area from '@turf/area'
export default {
  name: "RenameField",
  data () {
    return {
      description: undefined,
      valid: false,
      saveForm: false,
      name: undefined,
      currentField: undefined,
      rules: {
        required: value => !!value || 'Name is required',
      },
    }
  },
  props: {
  },
  watch: {
    saveForm(val) {
      if (!val) {
        this.currentField = undefined
        this.name = undefined
        if (this.$refs.form) this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    openDialog (field) {
      this.currentField = JSON.parse(JSON.stringify(field))
      this.name = this.currentField.name
      this.saveForm = true
    },
    submit () {
      this.$refs.form.validate()
      if (!this.valid) return
      this.$emit('saveField', {
        id: this.currentField.id,
        data: {
          name: this.name,
          geometry: this.currentField.geometry,
        }
      })
      this.saveForm = false
    }
  }
}
</script>

<style scoped>

</style>

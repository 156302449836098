<template>
  <v-layout column class="fill-height">
    <v-card class="fill-height" width="100%" style="border-radius: 0" :disabled="drawing">
      <div style="height: 150px; flex: none; border-bottom: 2.5px solid black;">
        <v-layout class="fill-height" column>
          <div style="height: 50px; flex: none; background-color: var(--v-primary-base);" class="px-1">
            <v-layout class="fill-height" align-center>
              <h3 class="ml-1">{{ router }}</h3>
              <v-spacer/>
              <v-btn icon v-if="currentField" @click="backToListField">
                <v-icon>mdi-chevron-left-circle</v-icon>
              </v-btn>
              <div v-if="!currentField" class="pr-1">
                <v-menu offset-y left v-model="menu" min-width="295" style="z-index: 400">
                  <template v-slot:activator="{ on }">
                    <v-tooltip left color="#000000">
                      <template v-slot:activator="{ on, attrs }" v-on="on">
                        <v-btn
                            @click="menu = true"
                            icon
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-swap-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>Sort</span>
                    </v-tooltip>
                  </template>
                  <v-card class="py-1 elevation-6" style="background-color: #282828">
                    <v-radio-group v-model="sort" hide-details class="mt-1">
                      <v-radio
                          color="secondary"
                          class="custom-checkbox"
                          v-for="(item, index) in sorts"
                          :key="index"
                          :label="item.name"
                          :value="item.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-card>
                </v-menu>
              </div>
            </v-layout>
          </div>
          <v-layout style="background-color: var(--v-backgroundList-base)" align-center class="pa-2 fill-height"
                    v-if="currentField">
            <div style="width: 75px; height: 75px; flex: none; position: relative; border-radius: 8px"
                 @click="zoomToField(field)"
                 v-if="!statusUpdate">
              <MapPreview :geojson="currentField.featureCollection" :id="'focus_' + currentField.layerId"/>
            </div>
            <v-layout style="flex: 1; height: 80px;" class="px-2 pl-4" column>
              <v-layout>
                <div class="text-truncate" style="font-size: 16px; color: #fafafa">{{ currentField.name }}</div>
                <v-spacer/>
                <v-tooltip left color="#000000">
                  <template v-slot:activator="{ on, attrs }" v-on="on">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        @click.native.stop="zoomToField(currentField)">
                      <v-icon color="button" small>mdi-crosshairs-gps</v-icon>
                      <!--                      <img alt="download" :src="require('@/assets/icon/fly.svg')">-->
                    </v-btn>
                  </template>
                  <span>Fly to</span>
                </v-tooltip>
                <v-menu offset-y top v-if="currentField && !sync">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        small
                        color="transparent"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="button" size="19">mdi-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-subheader>
                      Download
                    </v-subheader>
                    <v-list-item @click="() => $emit('download', 'tif')">
                      <v-list-item-title>{{ typeIndex.name }}.tiff</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => $emit('download', 'csv')">
                      <v-list-item-title class="caption">
                        {{ currentField.name + '_' + typeIndex.name }}.csv
                      </v-list-item-title>
                    </v-list-item>
                    <!--                      <v-list-item @click="download('vector')">-->
                    <!--                        <v-list-item-title>{{ type.name }}.shp</v-list-item-title>-->
                    <!--                      </v-list-item>-->
                    <v-list-item @click="() => $emit('download', 'contours')">
                      <v-list-item-title>Field.geojson</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => $refs.zoning.download()" v-if="group.id === 'zoning'">
                      <v-list-item-title>Zone.geojson</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small>
                      <v-icon color="button" size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense width="150" color="black" class="py-0">
                    <v-hover v-slot="{ hover }">
                      <v-list-item @click="editField(field)"
                                   :style="{'background-color': hover ? 'rgba(53,146,253,0.17)' : ''}">
                        <v-icon size="19" class="mr-3" color="secondary">mdi-pencil</v-icon>
                        <v-list-item-title style="color: var(--v-secondary-base) !important;">Edit</v-list-item-title>
                      </v-list-item>
                    </v-hover>
                    <v-hover v-slot="{ hover }">
                      <v-list-item @click="deleteField(field)"
                                   :style="{'background-color': hover ? 'rgba(253,53,53,0.17)' : ''}">
                        <v-icon size="19" class="mr-3" color="error">mdi-delete</v-icon>
                        <v-list-item-title style="color: var(--v-error-base) !important;">Delete</v-list-item-title>
                      </v-list-item>
                    </v-hover>
                  </v-list>
                </v-menu>
              </v-layout>
              <v-spacer/>
              <v-layout column>
                <span style="color:#79899A; font-size: 13px" class="pr-2" v-html="field.group"></span>
                <v-spacer/>
                <v-layout>
                  <div style="color:#79899A; font-size: 13px" class="pr-2 text-truncate"
                       v-html="field.current_season ? field.current_season.seasonYear + ' - ' + field.current_season.cropName : 'Unknown Crop'"></div>
                  <v-spacer/>
                  <span style="color:#79899A; font-size: 13px" class="pr-2"
                        v-html="transformArea(currentField.area)"></span>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-layout>
          <v-layout column style="background-color: var(--v-accent-base)" class="pa-2 pt-7 fill-height"
                    v-if="!currentField">
            <v-text-field v-model="search" solo-inverted hide-details dense label="Search" append-icon="mdi-magnify"/>
          </v-layout>
        </v-layout>
      </div>
      <v-layout column v-if="!currentField" style="background-color: var(--v-accent-base); height: 100%">
        <v-layout column style="overflow-y: auto; max-height: calc(100vh - 220px)">
          <div
              @click="() => $refs.addFieldForm.openDialog()"
              class="ml-1 py-3 fist-create-field"
              style="height: 100px; width: 98%; border: 1px dashed var(--v-secondary-base); background-color: rgba(3,121,205,0.16); cursor: pointer; transition-delay: 2s"
              v-if="displayBtnAddField"
          >
            <v-layout class="fill-height" align-center>
              <div style="width: 70px; height: 100%; flex: none">
                <v-layout align-center justify-center class="fill-height">
                  <v-icon size="50">mdi-shape-polygon-plus</v-icon>
                </v-layout>
              </div>
              <v-layout class="fill-height" column>
                <h4>Add your fields</h4>
                <span style="font-size: 13px; color: #9ea5b0">Browse satellite images, vegetation indices, weather data and much more</span>
              </v-layout>
            </v-layout>
          </div>
          <v-list width="100%" color="accent" class="pt-0" v-if="fields.length">
            <v-list-item-group>
              <template v-for="(field, index) in fields">
                <v-list-item class="px-1 my-1 mx-0" @click="goToField(field)"
                             style="background-color: #171717; border-radius: 8px">
                  <v-layout align-center class="py-2" :class="'farm-' + index">
                    <div style="width: 70px; height: 70px; flex: none; position: relative; border-radius: 8px">
                      <MapPreview :geojson="field.featureCollection" :id="field.layerId"/>
                    </div>
                    <v-layout style="flex: 1; height: 70px;" class="px-2 pl-4" column>
                      <v-layout>
                        <div class="text-truncate" style="font-size: 15px; color: #eaeaff">{{ field.name }}</div>
                        <v-spacer/>
                        <v-tooltip left color="#000000">
                          <template v-slot:activator="{ on, attrs }" v-on="on">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                @click.native.stop="zoomToField(field)">
                              <!--                              <img alt="download" :src="require('@/assets/icon/fly.svg')">-->
                              <v-icon color="button" small>mdi-crosshairs-gps</v-icon>

                            </v-btn>
                          </template>
                          <span>Fly to</span>
                        </v-tooltip>
                        <v-menu offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon small>
                              <v-icon color="button" size="21">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense width="150" color="black" class="py-0">
                            <v-hover v-slot="{ hover }">
                              <v-list-item @click="editField(field)"
                                           :style="{'background-color': hover ? 'rgba(53,146,253,0.17)' : ''}">
                                <v-icon size="19" class="mr-3" color="secondary">mdi-pencil</v-icon>
                                <v-list-item-title style="color: var(--v-secondary-base) !important;">Edit
                                </v-list-item-title>
                              </v-list-item>
                            </v-hover>
                            <v-hover v-slot="{ hover }">
                              <v-list-item @click="deleteField(field)"
                                           :style="{'background-color': hover ? 'rgba(253,53,53,0.17)' : ''}">
                                <v-icon size="19" class="mr-3" color="error">mdi-delete</v-icon>
                                <v-list-item-title style="color: var(--v-error-base) !important;">Delete
                                </v-list-item-title>
                              </v-list-item>
                            </v-hover>
                          </v-list>
                        </v-menu>
                      </v-layout>
                      <v-spacer/>
                      <v-layout column>
                        <span style="color:#79899A; font-size: 11.5px"
                              class="pr-2">{{ field.group ? field.group : '&nbsp;' }}</span>
                        <v-spacer/>
                        <v-layout style="padding-top: 2px">
                          <div v-if="field.current_season" style="color:#79899A; font-size: 11.5px"
                               class="pr-2 text-truncate"
                               v-html="field.current_season.seasonYear + ' - ' + field.current_season.cropName"></div>
                          <v-layout v-else>
                            <v-tooltip color="primary" bottom style="z-index: 999" v-if="group.id === 'dashboard'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                  <v-icon
                                      @click.native.stop="goToField(field, true)"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :color="hover ? 'yellow' : '#79899A'">
                                    mdi-flash-auto
                                  </v-icon>
                                </v-hover>
                              </template>
                              <span>Auto detect crop</span>
                            </v-tooltip>
                            <div style="color:#79899A; font-size: 11.5px;"
                                 class="text-truncate"
                                 :class="group.id !== 'zoning' ? 'pl-2' : ''"
                            >
                              Unknown Crop
                            </div>
                          </v-layout>
                          <v-spacer/>
                          <span style="color:#79899A; font-size: 11.5px" class="pr-2"
                                v-html="transformArea(field.area)"></span>
                        </v-layout>
                      </v-layout>
                    </v-layout>
                  </v-layout>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <InfiniteLoading
              direction="bottom"
              @infinite="loadMore"
              :identifier="infiniteIndex"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            <v-progress-circular
                width="3"
                slot="spinner"
                indeterminate
                color="navbar"
            ></v-progress-circular>
          </InfiniteLoading>
        </v-layout>
        <div style="width: 100%; height: 80px; flex: none; border-top: 3px solid black">
          <v-layout class="fill-height" align-center justify-center>
            <MenuAddField
                @refreshData="searchField"
                @openDrawTool="() => $emit('openDrawTool')"
                @openDetectTool="() => $emit('openDetectTool')"
                @saveField="data => $emit('saveField', data)"
                @openAddForm="(type) => $emit('openAddForm', type)"
            />
          </v-layout>
        </div>
      </v-layout>
      <v-layout column v-show="currentField && group.id === 'dashboard'"
                style="background-color: black; height: calc(100% - 140px)">
        <v-layout column style="overflow-y: auto; max-height: calc(100vh - 150px)">
          <div style="min-height: 175px; width: 100%; flex: none" class="mt-3">
            <v-layout style="background-color: var(--v-block-base)" column class="fill-height" v-if="currentField">
              <div style="flex: none; height: 60px" v-if="currentField.information && currentField.information.length">
                <v-layout class="fill-height px-2" align-center>
                  Crop rotation
                  <v-tooltip right max-width="500" color="#000000e4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          size="20">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      Keep record of a crop rotation history and plan the future sowing operations to maintain soil fertility,
                      thus reducing the risks of plant diseases and pests.
                      You can manage the field’s crop rotation in its editing settings.
                    </span>
                  </v-tooltip>
                  <v-spacer/>
                  <span v-if="currentField.information && currentField.information.length > 1"
                        style="color: var(--v-secondary-base); font-size: 13px; cursor: pointer"
                        @click="showMoreSeason = !showMoreSeason">
                    {{ showMoreSeason ? 'Show less' : 'Show more' }}
                  </span>
                </v-layout>
              </div>
              <v-layout v-if="currentField.information">
                <v-expansion-panels class="custom-expansion-v2">
                  <v-expansion-panel
                      v-for="(item,i) in avaiableSeasons"
                      :key="i"
                  >
                    <v-expansion-panel-header class="px-2">
                      <v-layout>
                        <v-layout column class="text-truncate pr-1" style="width: 120px">
                          <span style="color: var(--v-button-base);">Season {{ item.seasonYear }}</span>
                          <div class="pt-2 text-truncate" style="max-width: 100px">{{ item.cropName }}</div>
                        </v-layout>
                        <v-layout column class="text-truncate pr-1">
                          <span style="color: var(--v-button-base)">Sowing date</span>
                          <div class="pt-2">{{ item.sowingDate }}</div>
                        </v-layout>
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-2">
                      <v-layout style="font-size: 15px">
                        <v-layout column class="text-truncate pr-1" style="width: 120px">
                          <span style="color: var(--v-button-base)">Maturity</span>
                          <div class="pt-2">{{ item.maturity ? item.maturity : 'Default' }}</div>
                        </v-layout>
                        <v-layout column class="text-truncate pr-1">
                          <span style="color: var(--v-button-base)">Harvesting date</span>
                          <div class="pt-2">{{ item.harvestingDate }}</div>
                        </v-layout>
                      </v-layout>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
              <v-layout style="height: 30px" class="pa-2" align-start>
                Current weather
                <v-spacer/>
                <v-btn icon x-small @click="showWeather = !showWeather">
                  <v-icon v-if="!showWeather">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </v-layout>
              <v-layout style="height: 100px; font-size: 20px" class="pa-2 pl-4" column v-if="currentField.weather">
              <span>{{ new Date().toLocaleDateString() }}, {{ new Date().getHours() }}:00 - {{
                  new Date().getHours() + 1
                }}:00</span>
                <span style="font-size: 36px">{{ currentField.weather.temp }}°</span>
              </v-layout>
              <v-layout style="height: 160px; border-top: 2px solid black" column v-if="showWeather">
                <v-layout style="height: 80px" v-if="showWeather" class="pl-4 py-2">
                  <v-layout style="flex: 1;" column align-start justify-center>
                  <span style="color: var(--v-button-base)" class="pb-2">
                    Wind
                  </span>
                    <span>
                    {{ currentField.weather.wind }} m/s
                     <v-icon
                         size="16"
                         class="ml-0"
                         :style="{'transform': 'rotate(' + (90 - currentField.weather.wind_degree) + 'deg)'}">
                        mdi-navigation
                      </v-icon>
                  </span>
                  </v-layout>
                  <v-layout style="flex: 1;" column align-start justify-center>
                    <span style="color: var(--v-button-base)" class="pb-2">Humidity</span>
                    <span>
                  {{ currentField.weather.humidity }} %
                     <v-icon size="16" class="ml-0">
                      mdi-water
                    </v-icon>
                  </span>
                  </v-layout>
                </v-layout>
                <v-layout style="height: 80px;" v-if="showWeather" align-center class="pl-4">
                  <v-layout style="flex: 1;" column align-start justify-center>
                    <span style="color: var(--v-button-base)">Precipitation</span>
                    {{ currentField.weather.precipitation }} mm
                  </v-layout>
                  <v-layout style="flex: 1;" column align-start justify-center>
                    <span style="color: var(--v-button-base)" class="pb-2">Clouds</span>
                    <span>
                  {{ currentField.weather.cloud }} %
                     <v-icon size="16" class="ml-0">
                      mdi-cloud
                    </v-icon>
                  </span>
                  </v-layout>
                </v-layout>
              </v-layout>
            </v-layout>
          </div>
          <div style="height: 45px; width: 100%; flex: none" class="mt-2"
               v-if="currentField && currentField.survey_detail"
          >
            <v-layout style="background-color: var(--v-block-base)" align-center class="fill-height pl-2">
              <span style="font-size: 18px">Survey detail</span>
            </v-layout>
          </div>
          <div style="min-height: 56px; width: 100%; flex: none"
               v-if="currentField && currentField.survey_detail">
            <v-layout
                style="background-color: var(--v-accent-base); font-size: 15px"
                column
                class="py-2 fill-height"
            >
              <v-expansion-panels flat class="custom-expansion" multiple>
                <v-expansion-panel
                    v-for="(item, i) of currentField.survey_detail"
                    :key="i"
                >
                  <v-expansion-panel-header
                      style="height: 40px; min-height: 0"
                      color="accent"
                      class="pr-2 py-0">
                    {{ i }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="block" class="pl-3 pt-4">
                    <p style="color: #fafafa" v-for="(attr, index) in item" :key="index">{{ attr.name }}:
                      <span style="color: var(--v-button-base)">{{ attr.value }}</span>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
          </div>
          <v-layout style="height: 100%; width: 100%; min-height: 2px" class="mt-1">
            <v-layout
                style="background-color: var(--v-block-base)"
                align-center
                class="pa-2 fill-height"
                v-if="currentField">
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
      <v-layout column v-show="currentField && group.id === 'zoning'" class="px-1"
                style="background-color: black; height: calc(100% - 140px)">
        <Zoning
            ref="zoning"
            :images.sync="images"
            :index.sync="typeIndex"
            :currentField.sync="currentField"
            @removeLayer="id => $emit('removeLayer', id)"
            @addToMap="vectorData => $emit('addToMap', vectorData)"
            @selectMonth="month => $emit('selectMonth', month)"
        />
      </v-layout>
      <DeleteForm ref="deleteForm" @onSubmit="submitDeleteField"/>
      <RenameField ref="renameField" @saveField="updateField"/>
      <DetectCrop ref="autoDetect" @refreshData="selectField"/>
    </v-card>
    <v-overlay :value="mapLoading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import AddField from "./AddField"
import DeleteForm from "@/components/DeleteForm"
import MiniMap from "@/components/MiniMap"
import RenameField from "@/components/home/dashboard/FieldControl/RenameField"
import InfiniteLoading from "vue-infinite-loading"
import MapPreview from "@/components/MapPreview"
import {
  deleteField,
  getChartData,
  getField,
  getFieldInfo,
  updateField
} from "@/backend"
import {debounce} from "lodash"
import bbox from "@turf/bbox"
import AreaTransform from "@/ultis/convertArea"
import {mapState} from "@/store/ults";
import sleep from "@/ultis/sleep";
import MenuAddField from "@/components/home/dashboard/FieldControl/MenuAddField";
import SelectIndex from "@/components/SelectIndex";
import DetectCrop from "@/components/home/dashboard/FieldControl/DetectCrop";
import DatePicker from "@/components/DatePicker";
import Zoning from "@/components/home/dashboard/FieldControl/Zoning";

export default {
  name: "index",
  components: {
    Zoning,
    DatePicker,
    DetectCrop,
    SelectIndex, MenuAddField, MapPreview, RenameField, MiniMap, DeleteForm, AddField, InfiniteLoading
  },
  data(vm) {
    return {
      images: [],
      isDetect: false,
      statusUpdate: false,
      firstLoad: true,
      menu: false,
      mapLoading: false,
      state: undefined,
      page: 1,
      search: '',
      infiniteIndex: 1,
      desc: true,
      sort: '-created_at',
      sorts: [{
        name: 'Newest',
        value: '-created_at'
      }, {
        name: 'Oldest',
        value: 'created_at'
      }, {
        name: 'Field Name: A - Z',
        value: 'name'
      }, {
        name: 'Field Name: Z - A',
        value: '-name'
      }, {
        name: 'Field area: low to high',
        value: 'area'
      }, {
        name: 'Field area: high to low',
        value: '-area'
      }],
      router: 'Fields',
      showDetail: false,
      showWeather: true,
      showMoreSeason: false
    }
  },
  props: {
    drawing: {type: Boolean, default: false},
    listField: {type: Array, default: () => []},
    loading: {type: Boolean, default: false},
    field: {},
    dates: {type: Array, default: () => []},
    index: {type: String, default: ''},
    typeIndex: {},
    legendMenu: {},
    mapLegend: {type: Array, default: () => []},
    customLegend: {},
    sync: {}
  },
  mounted() {
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    ...mapState("main", ["group"]),
    legends: {
      get() {
        return this.customLegend
      },
      set(val) {
        this.$emit('update:customLegend', val)
      }
    },
    avaiableSeasons() {
      if (this.currentField.information && this.currentField.information.length) {
        if (this.showMoreSeason) return this.currentField.information
        return [this.currentField.information[0]]
      } else return []
    },
    fields: {
      get() {
        return this.listField
      },
      set(val) {
        this.$emit('update:listField', val)
      }
    },
    currentField: {
      get() {
        return this.field
      },
      set(val) {
        this.$emit('update:field', val)
      }
    },
    isLoading: {
      get() {
        return this.loading
      },
      set(val) {
        this.$emit('update:loading', val)
      }
    },
    displayBtnAddField() {
      return (!this.search && !this.fields.length && !this.mapLoading && !this.firstLoad)
    }
  },
  watch: {
    isDetect(val) {
      if (val) this.$emit('updateDate', val)
    },
    sort() {
      this.searchField()
    },
    search() {
      this.mapLoading = true
      this.$nextTick(() => {
        this.searchField()
      })
      this.searchField()
    },
    index(val) {
      this.getChartData(this.currentField)
    }
  },
  methods: {
    transformArea(area) {
      return AreaTransform.transformUnit(area)
    },
    searchField: debounce(function (e) {
      this.$emit('resetDraw')
      this.fields = []
      this.page = 1
      this.infiniteIndex = this.infiniteIndex + 1
    }, 300),
    async loadMore($state) {
      try {
        if ($state) this.state = $state
        let res = await getField({per_page: 10, page: this.page, search: this.search, sort: this.sort})
        if (res.data.data.length) {
          this.page = this.page + 1
          res.data.data.forEach(field => {
            field.layerId = 'aoi_' + field.id
            field.featureCollection = {
              "type": "FeatureCollection",
              "name": "field",
              "features": [
                {
                  "type": "Feature",
                  "properties": {},
                  "geometry": field.geometry
                }
              ]
            }
          })
          this.fields = this.fields.concat(res.data.data)
          this.state.loaded()
        } else {
          this.state.complete()
        }
      } catch (e) {
        this.state.loaded()
        this.state.complete()
      } finally {
        this.firstLoad = false
        this.mapLoading = false
      }
    },
    zoomToField(field) {
      this.$emit('zoomToField', bbox(field.featureCollection))
    },
    addField(data) {
      this.fields.push(data)
    },
    editField(field) {
      this.$emit('editField', field)
    },
    async updateField(data) {
      try {
        this.statusUpdate = true
        this.isLoading = true
        const res = await updateField(data)
        await this.searchField()
        this.isLoading = false
        if (this.currentField) {
          let currentEditField = res.data
          currentEditField.layerId = 'aoi_' + currentEditField.id
          currentEditField.featureCollection = {
            "type": "FeatureCollection",
            "name": "field",
            "features": [
              {
                "type": "Feature",
                "properties": {},
                "geometry": currentEditField.geometry
              }
            ]
          }
          await this.selectField(currentEditField.id)
        }
      } catch (e) {
        console.log(e)
        this.isLoading = false
      } finally {
        this.statusUpdate = false
      }
      // this.fields.find(val => val.layerId === data.field.layerId).featureCollection = data.featureCollection
    },
    deleteField(field) {
      this.$refs.deleteForm.openDeleteForm('Delete Field', `Are you sure you want to delete the field: <b style="font-size: 17px">${field.name}</b>`, field)
    },
    async submitDeleteField(field) {
      try {
        this.isLoading = true
        await deleteField(field.id)
        await this.searchField()
        this.$emit('refreshMap')
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        let index = this.tabs.findIndex(val => val.id === field.id)
        if (index >= 0) this.tabs.splice(index, 1)
      } catch (e) {

      } finally {
        this.isLoading = false
      }
      let index = this.fields.findIndex(val => val.layerId === field.layerId)
      if (index >= 0) this.fields.splice(index, 1)
      this.$emit('removeLayer', field.layerId)
    },
    async backToListField() {
      this.currentZoningData = {}
      this.fields = []
      this.page = 1
      this.$emit('destroyChart')
      this.$emit('closeColorPicker')
      this.router = 'Fields'
      this.currentField = undefined
      if (this.sync) this.$emit('changeStatusMap')
      this.$emit('mapSize')
      this.mapLoading = true
      this.$nextTick(() => {
        // this.searchField()
        this.$emit('setTab', 0)
      })
    },
    goToField(field, isDetect = false) {
      this.isDetect = isDetect
      if (this.isDetect) this.$emit('changeIndexToNDVI')
      if (!this.tabs.some(val => val.id === field.id)) {
        this.$store.commit('tabs/ADD_TAB', {
          closAble: true,
          icon: 'mdi-vector-rectangle',
          name: field.name,
          id: field.id
        })
      }
      this.$emit('setTab', this.tabs.findIndex(val => val.id === field.id))
    },
    async selectField(fieldId) {
      try {
        this.isLoading = true
        let res = await getFieldInfo(fieldId)
        res.data.layerId = 'aoi_' + res.data.id
        res.data.featureCollection = {
          "type": "FeatureCollection",
          "name": "field",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": res.data.geometry
            }
          ]
        }
        this.$emit('setField', res.data)
        this.$emit('removeAll')
        this.router = 'Field'
        await this.getChartData(res.data)
        await sleep(200)
        this.zoomToField(res.data)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      } finally {
      }
    },
    async getChartData(field) {
      try {
        this.mapLoading = true
        const res = await getChartData({
          id: field.id,
          data: {
            max_cloud: this.group.id === 'zoning' ? 0 : undefined,
            detect: this.isDetect,
            per_page: 'all',
            start_date: this.dates[0],
            end_date: this.dates[1],
            index: this.index
          }
        })
        if (!res.data.images.length) this.$store.commit('message/SHOW_ERROR', 'No data available')
        this.images = res.data.images
        if (this.$refs.zoning) {
          this.$refs.zoning.setDate(this.images[this.images.length - 1].id)
          this.$refs.zoning.reset()
        }
        this.$emit('displayChartData', res.data.images)
        if (res.data.crop && this.isDetect) {
          this.isDetect = false
          this.$refs.autoDetect.openDialog(res.data.crop, field.id)
        }
        this.mapLoading = false
      } catch (e) {
        this.mapLoading = false
      } finally {
        this.mapLoading = false
        this.isDetect = false
      }
    },
  }
}
</script>

<style scoped>
/deep/
.custom-checkbox .v-input--selection-controls__ripple {
  display: none !important;
}

/deep/
.custom-checkbox i {
  font-size: 22px !important;
}

.custom-checkbox {
  padding: 4px 20px;
  margin-bottom: 0 !important;
  height: 40px;
}

.custom-checkbox:hover {
  background-color: rgba(53, 146, 253, 0.17) !important;
}

/deep/
.custom-expansion .v-icon {
  font-size: 18px !important;
}

/deep/
.custom-expansion .v-expansion-panel-content__wrap {
  padding-bottom: 2px;
}

/deep/
.v-slide-group__prev {
  display: none;
}

/deep/
.v-slide-group__next {
  display: none;
}
</style>

<template>
  <v-layout column class="fill-height pa-2">
    <UploadField ref="upload" :currentFile.sync="file"/>
    <div style="width: 100%; height: auto; text-align: center">
      <v-layout column>
        <v-layout
            align-center
            style="background-color: var(--v-primary-base)"
            :style="{'border-bottom': valid ? '2px solid var(--v-error-base)' : '2px solid var(--v-success-base)'}"
            class="pa-2"
            v-if="file">
          <div class="text-truncate" style="font-size: 13px" :style="{color: valid ? 'var(--v-error-base)' : 'var(--v-success-base)'}">
            <v-icon size="20" :color="valid ? 'error' : 'success'">mdi-file</v-icon>
            {{ file[0].name }}
          </div>
          <v-spacer/>
          <v-btn @click.native.stop="file = undefined" icon small color="error"><v-icon size="20">mdi-close</v-icon></v-btn>
        </v-layout>
        <span class="mt-2" style="font-size: 13px; color: var(--v-secondary-base)">Formats: *.geojson  |  Max. size: 20MB</span>
        <span class="mt-1" style="font-size: 13px; color: var(--v-secondary-base)">The field area should not exceed 50 ha</span>
      </v-layout>
    </div>
    <v-spacer/>
    <v-btn :disabled="valid" width="100%" color="secondary" height="50" @click="submit" style="border-radius: 8px">Upload file</v-btn>
  </v-layout>
</template>

<script>
import UploadField from "@/components/home/dashboard/UploadField";

export default {
  name: "UploadForm",
  components: {UploadField},
  data() {
    return {
      file: undefined,
      valid: true
    }
  },
  methods: {
    submit() {
      if (this.valid) return
      for (let index = 0; index < this.file[0].geometry.features.length; index++) {
        this.$emit('saveField', {
          name: this.file[0].fileName + '_' + index,
          geometry: this.file[0].geometry.features[index].geometry,
          isSelectField: true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-layout column class="pa-2">
    <div style="flex: none; height: 80px">
      <v-layout class="fill-height" align-center>
        <v-text-field
            v-model="recordFilter.search"
            @input="debounceInput"
            solo-inverted
            hide-details
            dense
            label="Search"
            append-icon="mdi-magnify"/>
      </v-layout>
    </div>
    <v-data-table
        v-model="selectedFarm"
        single-select
        @click:row="rowClick"
        height="100%"
        style="height: calc(100vh - 250px)"
        fixed-header
        :headers="headers"
        :items="reports"
        :search="recordFilter.search"
        :loading="loading"
        :items-per-page="15"
        hide-default-header
        class="row-pointer order-table d-flex flex-column"
        hide-default-footer
        :server-items-length.sync="totalItem"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :page.sync="page"
    >
      <template v-slot:[`item.preview`]="{ item }">
        <div style="width: 60px; height: 60px" class="py-1">
          <MapPreview :geojson="item.featureCollection" :id="'farm_' + item.id"/>
        </div>
      </template>
    </v-data-table>
    <v-spacer/>
    <div class="text-center" style="flex: none; height: auto">
      <v-pagination :total-visible="3" v-model="page" :length="pageCount"></v-pagination>
    </div>
    <div style="flex: none; height: 50px">
      <v-btn width="100%" height="50" color="secondary" @click="submit" style="border-radius: 8px">Submit</v-btn>
    </div>
    <SaveField ref="saveField" @saveField="(data) => $emit('saveField', data)"/>
  </v-layout>
</template>

<script>
import {debounce} from "lodash"
import SaveField from "@/components/home/dashboard/FieldControl/SaveField";
import MapPreview from "@/components/MapPreview";
import {getRecords} from "@/backend";

export default {
  name: "ImportFromSurvey",
  components: {MapPreview, SaveField},
  data() {
    return {
      firstLoad: true,
      dialog: false,
      selectedFarm: undefined,
      step: 1,
      type: undefined,
      loading: false,
      headers: [
        {text: "Preview", value: "preview", sortable: false, align: "start"},
        {text: "Survey name", value: "survey", sortable: true, align: "start"},
      ],
      totalItem: 100,
      pageCount: 0,
      recordFilter: {
        search: undefined,
      },
      reports: [],
      page: 1,
      options: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.getRecord()
      },
      deep: true
    }
  },
  methods: {
    resetData() {
      this.reports = []
      this.recordFilter.search = ''
      this.page = 1
      this.selectedFarm = undefined
      this.file = undefined
    },
    rowClick: function (item, row) {
      row.select(true)
      this.selectedFarm = [item]
    },
    getParamRecord() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = '-' + this.options.sortBy[0]
        else sort = this.options.sortBy[0]
      }
      let param = JSON.parse(JSON.stringify(this.recordFilter))
      param['per_page'] = 15
      param['page'] = this.options.page
      param['sort'] = sort
      return param
    },
    debounceInput: debounce(function (e) {
      this.getRecord()
    }, 500),
    async getRecord() {
      try {
        this.selectedFarm = undefined
        this.reports = []
        this.loading = true
        const resRecord = await getRecords(this.getParamRecord())
        this.reports = resRecord.data.data
        this.reports.forEach(field => {
          field.featureCollection = {
            "type": "FeatureCollection",
            "name": 'farm_' + field.id,
            "features": [
              {
                "properties": {"farm_id": field.id},
                "type": "Feature",
                "geometry": JSON.parse(field.geometry)
              }]
          }
        })
        this.totalItem = resRecord.data.total
        this.pageCount = resRecord.data.last_page
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    submit() {
      this.$refs.saveField.openSaveForm(this.selectedFarm[0].featureCollection, this.selectedFarm[0])
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
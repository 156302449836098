<template>
  <v-layout column class="fill-height">
    <v-card class="fill-height" width="100%" style="border-radius: 0; background-color: red" color="accent">
      <v-layout column style="height: 100%">
        <div style="flex: none; height: 50px; background-color: var(--v-primary-base)">
          <v-layout class="fill-height px-2" align-center>
            <h3 v-if="addType === 'file'">Upload Field</h3>
            <h3 v-if="addType === 'survey'">Import from survey</h3>
            <h3 v-if="addType === 'detect'">Auto detection</h3>
            <v-spacer/>
            <v-btn @click="backToListField" icon small>
              <v-icon>mdi-chevron-left-circle</v-icon>
            </v-btn>
          </v-layout>
        </div>
        <v-layout class="fill-height">
          <UploadForm v-if="addType === 'file'" @saveField="submitGeoJson"/>
          <ImportFromSurvey v-if="addType === 'survey'" @saveField="submitGeoJson"/>
          <DetectTool
              v-if="addType === 'detect'"
              @deleteAll="() => $emit('deleteAll')"
              @submitDetection="() => $emit('submitDetection')"
              @addDraw="data => $emit('addDraw', data)"
              @saveField="saveGeometry"
          />
        </v-layout>
      </v-layout>
    </v-card>
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import UploadForm from "@/components/home/dashboard/addField/UploadForm";
import ImportFromSurvey from "@/components/home/dashboard/addField/ImportFromSurvey";
import DetectTool from "@/components/home/dashboard/addField/DetectTool";

export default {
  name: "AddField",
  components: {DetectTool, ImportFromSurvey, UploadForm},
  data() {
    return {
      loading: false
    }
  },
  props: {
    addType: {type: String, default: ''}
  },
  methods: {
    backToListField() {
      this.$emit('refreshData')
      this.$parent.isAddField = false
    },
    submitGeoJson(data) {
      this.$emit('saveField', data)
      this.$parent.isAddField = false
    },
    saveGeometry() {
      this.$emit('saveGeometry')
      this.$parent.isAddField = false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-layout column style="overflow-y: auto; max-height: calc(100vh - 150px)">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-tabs
        fixed-tabs
        height="40px"
        dark
        :show-arrows="false"
        v-model="tab"
    >
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab style="font-size: 11px">
        Vegetation maps
      </v-tab>
      <v-tab style="font-size: 11px">
        Saved maps
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="height: 100%">
      <v-tab-item style="height: 100%">
        <v-layout class="fill-height pt-5" column>
          <v-layout justify-center class="py-2">
            <div style="height: 32px; width: 210px; border: 2px solid var(--v-secondary-base); border-radius: 28px"
                 class="px-3">
              <v-tabs
                  fixed-tabs
                  height="26"
                  dark
                  :show-arrows="false"
                  v-model="subTab"
              >
                <v-tab style="font-size: 9px; height: 30px; border-right: 2px solid var(--v-button-base)" class="px-1">
                  Index
                </v-tab>
                <v-tab :disabled="!Object.keys(currentZoningData).length"
                       style="font-size: 9px; height: 30px;" class="px-1">
                  Zones
                </v-tab>
              </v-tabs>
            </div>
          </v-layout>
          <v-tabs-items v-model="subTab" style="height: 100%">
            <v-tab-item style="height: 100%">
              <v-layout column class="fill-height pa-2 pt-5">
                <div style="width: 100%; border: 1px solid white; border-radius: 4px" class="my-2">
                  <v-select
                      @change="selectImage"
                      dense
                      background-color="transparent"
                      solo-inverted
                      hide-details
                      v-model="zoning.image_id"
                      item-text="date"
                      item-value="id"
                      :items="images"
                  >
                    <template v-slot:prepend-inner>
                      <v-layout class="fill-height" align-center>
                        <span style="font-size: 12px; color: white; margin-top: 2px" class="mr-2">Date:</span>
                      </v-layout>
                    </template>
                    <template v-slot:item="{ item }">
                      <span
                          style="font-size: 14px"
                          :style="{'color': item.id === zoning.image_id ? 'var(--v-secondary-base)': ''}">
                        <v-icon small class="mr-1">mdi-calendar-month</v-icon>{{ item.date }}
                        <v-icon small class="ml-4 mr-1">mdi-cloud-outline</v-icon>
                        {{ item.field_cloud_cover }} %
                      </span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span style="font-size: 14px; color: white" class="pt-1">
                         {{ item.date }}
                        <v-icon small class="ml-4 mr-1">mdi-cloud-outline</v-icon>
                        {{ item.field_cloud_cover }} %
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-tooltip right open-on-hover color="primary" max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>
                                Select an image, based on which a differential fertilizer map will be calculated. Only cloudless images are available for selection.
                              </span>
                      </v-tooltip>

                      <v-hover>
                      </v-hover>
                    </template>
                  </v-select>
                </div>
                <div style="width: 100%; border: 1px solid white; border-radius: 4px" class="my-2">
                  <v-select
                      dense
                      background-color="transparent"
                      solo-inverted
                      hide-details
                      placeholder="Zones"
                      v-model="zoning.zone"
                      :items="zones"
                  >
                    <template v-slot:append>
                      <v-tooltip right open-on-hover color="primary" max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>
The number of zones, into which the differential fertilizer map will be split. For each zone, you can set different values for the amount of fertilizers or plant protection products to be applied.                              </span>
                      </v-tooltip>

                      <v-hover>
                      </v-hover>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-layout class="fill-height" align-center>
                        <span style="font-size: 12px; color: white; margin-top: 2px" class="mr-2">Zones:</span>
                      </v-layout>
                    </template>
                    <template v-slot:item="{ item }">
                      <span style="font-size: 14px"
                            :style="{'color': item === zoning.zone ? 'var(--v-secondary-base)': ''}">{{ item }}</span>
                    </template>
                  </v-select>
                </div>
                <v-spacer/>
                <div style="width: 100%; flex: none">
                  <v-layout>
                    <v-btn :disabled="!Object.keys(currentZoningData).length" color="error" width="45" min-width="0"
                           height="45"
                           @click="clearZone"
                           style="border-radius: 8px">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-layout class="pl-2">
                      <v-btn color="secondary" width="100%" height="45" style="border-radius: 8px"
                             @click="getZoningData">
                        Calculate
                      </v-btn>
                    </v-layout>
                  </v-layout>
                </div>
              </v-layout>
            </v-tab-item>
            <v-tab-item style="height: 100%">
              <v-layout class="fill-height" column>
                <v-layout class="fill-height overflow-y-auto py-3" column
                          v-if="Object.keys(currentZoningData).length"
                          style="background-color: var(--v-primary-base); border-radius: 8px">
                  <!--                  <div class="color-text px-2" style="flex: none; width: 100%">-->
                  <!--                    <v-layout column>-->
                  <!--                      <v-layout class="color-text">-->
                  <!--                        Opacity-->
                  <!--                        <v-spacer/>-->
                  <!--                        {{ zoneOpacity * 100 }} %-->
                  <!--                      </v-layout>-->
                  <!--                      <v-slider-->
                  <!--                          class="px-2"-->
                  <!--                          :min="0"-->
                  <!--                          :max="1"-->
                  <!--                          :step="0.1"-->
                  <!--                          color="secondary"-->
                  <!--                          v-model="zoneOpacity"-->
                  <!--                          track-color="white"-->
                  <!--                          thumb-color="secondary"-->
                  <!--                          thumb-label-->
                  <!--                          thumb-size="22"-->
                  <!--                          hide-details-->
                  <!--                      ></v-slider>-->
                  <!--                    </v-layout>-->
                  <!--                  </div>-->
                  <div style="font-size: 12px" class="color-text pt-5 px-2">Price per one unit (₱/UOM)</div>
                  <div class="px-2">
                    <v-text-field type="number" :min="0" dense hide-details solo-inverted solo
                                  v-model="currentZoningData.price"></v-text-field>
                  </div>
                  <div style="height: 40px; width: 100%; flex: none; border-bottom: 2px solid black" class="mt-1">
                    <v-layout class="fill-height" align-center>
                      <div class="fill-height" style="width: 190px; font-weight: bold; flex: none">
                        <v-layout align-center class="fill-height px-3 color-text" style="font-size: 12px">
                          Zones
                        </v-layout>
                      </div>
                      <v-layout class="fill-height pr-2 color-text" style="font-size: 12px; font-weight: bold"
                                align-center>
                        Rate (UOM/ha)
                      </v-layout>
                    </v-layout>
                  </div>
                  <div v-if="currentZoningData.geojson" style="height: 80px; width: 100%; flex: none" class="mt-1"
                       v-for="(item, index) in currentZoningData.geojson ? currentZoningData.geojson.features : []"
                       :key="index"
                  >
                    <v-layout class="fill-height" align-center>
                      <div class="fill-height" style="width: 190px; flex: none">
                        <v-layout column class="fill-height px-3">
                          <v-layout align-center style="flex: 1">
                            <v-icon :color="currentZoningData.colors[index]" size="14" class="mr-2">
                              mdi-checkbox-blank-circle
                            </v-icon>
                            <div class="text-truncate" style="font-size: 14px">{{ item.properties.zone }}</div>
                          </v-layout>
                          <v-layout align-center style="flex: 1">
                            <div class="text-truncate color-text" style="font-size: 12px">
                              {{ transformArea(item.properties.area) }}
                            </div>
                          </v-layout>
                          <v-layout align-center style="flex: 1">
                            <div class="text-truncate color-text" style="font-size: 12px">Average:
                              {{ item.properties.average.toFixed(2) }}
                            </div>
                          </v-layout>
                        </v-layout>
                      </div>
                      <v-layout class="fill-height pr-2" align-center>
                        <v-text-field type="number" :min="0" dense hide-details solo-inverted solo
                                      v-model="item.properties.rate"></v-text-field>
                      </v-layout>
                    </v-layout>
                  </div>
                  <div style="flex: none; width: 100%; border-top: 2px solid black" class="py-5 px-2">
                    <v-layout>
                      <div style="flex: none; width: 120px">
                        <v-layout align-center style="font-size: 14px;" class="color-text">
                          Total amount of fertilizer:
                        </v-layout>
                      </div>
                      <v-layout align-center justify-end style="font-size: 14px">
                        {{ transformNumber(total) }} UOM
                      </v-layout>
                    </v-layout>
                  </div>
                  <div style="flex: none; width: 100%; border-top: 2px solid black" class="py-5 px-2">
                    <v-layout>
                      <div style="flex: none; width: 120px">
                        <v-layout align-center style="font-size: 14px;" class="color-text">
                          Total budget:
                        </v-layout>
                      </div>
                      <v-layout align-center justify-end style="font-size: 14px">
                        {{ transformNumber((total * currentZoningData.price).toFixed(2)) }} ₱
                      </v-layout>
                    </v-layout>
                  </div>
                  <div style="flex: none; width: 100%; height: 200px; border-top: 2px solid black" class="pa-5">
                    <v-layout column>
                      <div class="color-text">Total savings calculator</div>
                      <div style="font-size: 12px" class="color-text pt-5">Flat rate application (UOM/ha)</div>
                      <div>
                        <v-text-field type="number" :min="0" dense hide-details solo-inverted solo
                                      v-model="currentZoningData.flat_rate"></v-text-field>
                      </div>
                      <div style="flex: none; width: 100%" class="py-5">
                        <v-layout>
                          <div style="flex: none; width: 100px">
                            <v-layout align-center style="font-size: 12px;" class="color-text">
                              Total amount of fertilizer:
                            </v-layout>
                          </div>
                          <v-layout align-center justify-end style="font-size: 12px">
                            {{ transformNumber(totalArea) }} UOM
                          </v-layout>
                        </v-layout>
                      </div>
                      <div style="flex: none; width: 100%" class="py-5">
                        <v-layout>
                          <div style="flex: none; width: 100px">
                            <v-layout align-center style="font-size: 12px;" class="color-text">
                              Total budget:
                            </v-layout>
                          </div>
                          <v-layout align-center justify-end style="font-size: 12px">
                            {{ transformNumber((totalArea * currentZoningData.price).toFixed(2)) }} ₱
                          </v-layout>
                        </v-layout>
                      </div>
                    </v-layout>
                  </div>
                </v-layout>
                <div style="width: 100%; flex: none" class="pa-2">

                  <v-btn color="secondary" width="100%" height="50" style="border-radius: 8px"
                         @click="saveZone">
                    Save
                  </v-btn>
                </div>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-layout>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dense>
              <v-list-item v-for="(map, index) in maps" :key="index" @click="changTab(map)"
                           style="border-bottom: 1px solid #757575">
                <v-list-item-icon>
                  <v-icon color="button">mdi-layers</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ map.date }} / {{ map.index }} / {{ map.zone }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon x-small color="red" @click.native.stop="deleteMap(map)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <DeleteForm ref="deleteMap" @onSubmit="submitDeleteMap"/>
  </v-layout>
</template>

<script>
import {calculateZoning, deleteMap, getmap, getMapDetail, saveZone} from "@/backend";
import utils from "@/ultis/genUUID";
import DeleteForm from "@/components/DeleteForm"
import AreaTransform from "@/ultis/convertArea";
import numberFormat from "@/ultis/comma";

export default {
  name: "Zoning",
  components: {DeleteForm},
  data() {
    return {
      loading: false,
      zoneOpacity: 1,
      totalAmountOfFertilize: {
        final: 0
      },
      totalAmountOfFertilizeTwo: {
        final: 0
      },
      maps: [],
      currentZoneLayers: undefined,
      currentZoningData: {},
      zoning: {
        image_id: undefined,
        zone: 3,
      },
      zones: [2, 3, 4, 5, 6],
      subTab: null,
      tab: null,
    }
  },
  props: {
    images: {type: Array, default: () => []},
    index: {
      type: Object, default: () => {
      }
    },
    currentField: {
      type: Object, default: () => {
      }
    },
  },
  watch: {
    tab(val) {
      if (val) this.getMaps()
    },
    subTab(val) {
      if (!val && this.currentZoneLayers) this.$emit('removeLayer', this.currentZoneLayers.id)
      else if (this.currentZoneLayers) this.$emit('addToMap', this.currentZoneLayers)
    },
  },
  computed: {
    totalArea() {
      let total = 0
      if (Object.keys(this.currentZoningData).length) {
        this.currentZoningData.geojson.features.forEach(feature => {
          total = total + feature.properties.area * 0.0001 * this.currentZoningData.flat_rate
        })
      }
      return total.toFixed(2)
    },
    total() {
      let total = 0
      if (Object.keys(this.currentZoningData).length) {
        this.currentZoningData.geojson.features.forEach(feature => {
          total = total + feature.properties.area * 0.0001 * feature.properties.rate
        })
      }
      return total.toFixed(2)
    },
  },
  methods: {
    download() {
      if (!Object.keys(this.currentZoningData).length) return (this.$store.commit('message/SHOW_ERROR', 'Please, select zone for download!'))
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.currentZoningData.geojson));
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute("href", dataStr)
      downloadAnchorNode.setAttribute("download", this.currentField.name + "_zones.geojson")
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    clearZone() {
      this.currentZoningData = {}
      if (this.currentZoneLayers) {
        this.$emit('removeLayer', this.currentZoneLayers.id)
        this.currentZoneLayers = undefined
      }
    },
    setDate(id) {
      this.zoning.image_id = id
    },
    selectImage(id) {
      this.$emit('selectMonth', this.images.find(image => image.id === id))
    },
    transformNumber(number) {
      return numberFormat.numberWithCommas(number)
    },
    async saveZone() {
      try {
        this.loading = true
        const res = await saveZone({
          fieldId: this.currentField.id,
          id: this.currentZoningData.id,
          data: this.currentZoningData
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', 'Error')
      } finally {
        this.loading = false
      }
    },
    async getMaps() {
      try {
        this.loading = true
        this.maps = []
        const res = await getmap(this.currentField.id)
        this.maps = res.data.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    deleteMap(map) {
      this.$refs.deleteMap.openDeleteForm('Delete Map', `Are you sure you want to delete the map: <b style="font-size: 17px">${map.date}</b>`, map)
    },
    async submitDeleteMap(map) {
      try {
        this.loading = true
        await deleteMap({fieldId: this.currentField.id, id: map.id})
        await this.getMaps()
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', 'Server error')
      } finally {
        this.loading = false
      }
    },
    async changTab(map) {
      try {
        this.loading = true
        const res = await getMapDetail({fieldId: this.currentField.id, id: map.id})
        this.currentZoningData = res.data
        let vectorData = this.transformData(res.data)
        if (this.currentZoneLayers) {
          this.$emit('removeLayer', this.currentZoneLayers.id)
          this.currentZoneLayers = undefined
        }
        this.$emit('addToMap', vectorData)
        this.currentZoneLayers = vectorData
        this.tab = 0
        this.subTab = 1
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', 'Server error')
      } finally {
        this.loading = false
      }
      // this.currentZoningData = map
      // this.animateNumber(1000000, 1000, 0)
    },
    async getZoningData() {
      try {
        this.zoning.index = this.index.code
        this.loading = true
        let params = {
          id: this.currentField.id,
          data: this.zoning
        }
        const res = await calculateZoning(params)
        this.currentZoningData = res.data
        let vectorData = this.transformData(res.data)
        if (this.currentZoneLayers) this.$emit('removeLayer', this.currentZoneLayers.id)
        this.currentZoneLayers = vectorData
        this.subTab = 1
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', 'Server error')
      } finally {
        this.loading = false
      }
    },
    transformData(data) {
      return {
        'id': utils.getUUID(),
        'type': 'fill',
        'geometry': data.geojson,
        'color': 'red',
        'meta': undefined,
        'style': this.transformPaint(data.colors ? data.colors : [])
      }
    },
    transformPaint(colors) {
      let fillColor = [
        'case'
      ]
      colors.forEach((val, index) => {
        fillColor.push(['==', ["get", 'zone'], `Zone ${index + 1}`])
        fillColor.push(val)
      })
      fillColor.push('transparent')
      return {
        'fill-color': fillColor,
        'fill-opacity': 1
      }
    },
    transformArea(area) {
      return AreaTransform.transformUnit(area)
    },
    reset() {
      this.maps = []
      this.currentZoneLayers = undefined
      this.currentZoningData = {}
      this.tab = 0
      this.subTab = 0
    }
  }
}
</script>

<style scoped>
.color-text {
  color: #79898A;
}

/deep/
.v-tab--active {
  color: var(--v-secondary-base) !important;
}
</style>
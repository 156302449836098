<template>
<v-dialog
  persistent
  v-model="deleteForm"
  width="450">
  <v-card>
    <v-card-text class="pt-6 px-4" style="background-color: var(--v-primary-base)">
      <v-btn icon small @click="deleteForm = false" style="position: absolute; top: 10px; right: 10px">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-layout align-center justify-center>
        <span style="font-size: 18px; text-transform: uppercase; font-weight: bold">{{title}}</span>
      </v-layout>
      <v-layout align-center justify-center style="height: 50px">
        <span v-html="description"></span>
      </v-layout>
    </v-card-text>
    <v-card-actions style="background-color: var(--v-primary-base)" class="pb-6 px-4">
      <v-spacer/>
      <v-btn width="100" color="warning" @click="submitDelete" dark>Delete</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "DeleteForm",
  data () {
    return {
      deleteForm: false,
      title: 'Delete',
      description: '',
      currentItem: undefined
    }
  },
  props: {
  },
  methods: {
    openDeleteForm (title, description, currentItem) {
      this.currentItem = currentItem
      this.title = title
      this.description = description
      this.deleteForm = true
    },
    submitDelete() {
      this.$emit('onSubmit', this.currentItem)
      this.deleteForm = false
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-layout style="height: 100%; background: transparent; border-radius: 0" column>
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-layout class="fill-height">
      <v-layout
          v-show="!sync"
          class="fill-height"
          style="flex: 2; border-right: 4px black solid; overflow-y: hidden; min-width: 310px; background-color: transparent">
        <AddField
            v-if="isAddField"
            :addType.sync="addType"
            @saveField="saveField"
            @deleteAll="() => $refs.map.resetDraw"
            @submitDetection="() => $refs.map.submitDetection()"
            @addDraw="(data) => $refs.map.addDrawToMap(data)"
            @saveGeometry="() => $refs.map.saveGeometry()"
        />
        <FieldControl
            v-show="!isAddField"
            ref="fieldControl"
            :field.sync="currentField"
            :dates.sync="dates"
            :index.sync="type.code"
            :loading.sync="loading"
            :listField.sync="listField"
            :drawing.sync="drawing"
            :typeIndex.sync="type"
            :legendMenu.sync="legendMenu"
            :customLegend.sync="customLegend"
            :sync.sync="sync"
            :mapLegend.sync="mapLegend"
            @resetDraw="() => $refs.map.resetDraw"
            @selectMonth="selectMonth"
            @addToMap="(data) => $refs.map.addZoningData(data)"
            @changeIndexToNDVI="changeIndexToNDVI"
            @updateDate="changeDate"
            @download="download"
            @changeIndex="changeIndex"
            @destroyChart="destroyChart"
            @refreshMap="() => $refs.map.refreshMap()"
            @saveField="saveField"
            @setField="setCurrentField"
            @setTab="index => tab = index"
            @displayChartData="displayChartData"
            @removeAll="removeAllMap"
            @closeColorPicker=" () => $refs.map.closeColorPicker()"
            @zoomToField="bbox => $refs.map.submitZoom(bbox)"
            @editField="editField"
            @openDrawTool="openDrawTool"
            @openAddForm="openAddForm"
            @openDetectTool="() => $refs.map.openDetectTool()"
            @changeStatusMap="() => $refs.map.changeStatusMap()"
            @mapSize="() => $refs.map.reSize()"
            @removeLayer="(layerId) => $refs.map.removeLayer(layerId)"
        />
      </v-layout>
      <v-layout style="flex: 10; height: 100%;">
        <v-layout column class="fill-height">
          <div style="flex: none; height: 40px; width: 100%; background-color: #1E1E1E;">
            <v-tabs v-model="tab" class="px-2 py-1 custom-tab" hide-slider style="height: 40px">
              <v-tab
                  :disabled="drawing || isAddField"
                  v-for="(item, index) in tabs"
                  :key="index"
                  style="width: 170px; height: 32px; border-radius: 12px"
                  class="text-truncate px-0"
                  :style="{'background-color': index === tab ? 'rgba(255,255,255,0.24)' : ''}">
                <v-layout class="text-truncate px-4"
                          style="width: 100px; text-transform: none; font-size: 13px; text-align: start; color: #fafafa; font-weight: normal"
                          :title="item.name">
                  <v-icon class="mr-2" size="20">{{ item.icon }}</v-icon>
                  <span style="font-size: 13px">
                     {{ item.name }}
                  </span>
                </v-layout>
                <v-btn class="mr-2" icon x-small v-if="item.closAble" @click.native.stop="tabs.splice(index, 1)">
                  <v-icon size="16">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-tab>
            </v-tabs>
          </div>
          <div class="flex d-flex map-view" style="flex: 8; position: relative; width: 100%; height: 100%">
            <v-layout class="map-legend">
              <div
                  style="background-color: var(--v-primary-base); border: 1px solid #5d5d5d; height: 40px; border-radius: 4px"
                  v-show="!drawing">
                <v-layout class="fill-height" align-center>
                  <div class="fill-height pl-2" style="width: 180px;">
                    <SelectIndex
                        left
                        @changeIndex="changeIndex"
                        text
                        :type.sync="type"
                        :legendMenu.sync="legendMenu"
                        :customLegend.sync="customLegend"
                        :mapLegend.sync="mapLegend"
                        :width="'95%'"/>
                  </div>
                  <v-layout class="fill-height" v-if="type.data && group.id === 'dashboard'">
                    <v-spacer/>
                    <v-btn text @click="showLegend = !showLegend" height="38" width="40" small color="white">
                      <v-icon size="22" v-if="!showLegend">mdi-arrow-expand</v-icon>
                      <v-icon size="22" v-else>mdi-arrow-collapse</v-icon>
                    </v-btn>
                  </v-layout>
                </v-layout>
                <v-layout
                    style="height: 175px; font-size: 12px; overflow-y: auto; background-color: var(--v-primary-base)"
                    class="px-2 py-1" column
                    v-if="showLegend && type.data">
                  <v-layout style="max-height: 30px" align-center v-for="(item, i) in type.data" :key="i">
                    <div
                        :style="{'background-color': item.color}"
                        style="width: 25px; height: 25px; "
                        class="mr-3">
                    </div>
                    <span style="font-size: 14px; width: 100px">{{ item.value }}</span>
                    <span style="font-size: 14px; width: 120px">{{ item.name }}</span>
                    <v-spacer/>
                    <div style="width: 100px" v-if="item.area">
                      <span style="font-size: 14px" v-html="item.area"></span>
                    </div>
                    <v-spacer/>
                  </v-layout>
                </v-layout>
              </div>
              <v-layout class="ml-2" style="width: 275px" v-if="currentField && !sync && !drawing">
                <div style="width: 245px" class="mr-2">
                  <DateRangerPicker outlined dense left label="" v-model="dates"/>
                </div>
                <div>
                  <v-btn
                      color="secondary"
                      height="38"
                      width="38"
                      min-width="0"
                      @click="() => $refs.fieldControl.getChartData(this.currentField)">
                    <v-icon size="20">mdi-filter-outline</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-layout>
            <div class="elevation-2 split-map-legend" v-if="sync">
              <v-layout class="fill-height" align-center>
                <div class="fill-height pl-2" style="width: 180px;">
                  <SelectIndex
                      right
                      :mapLegend.sync="mapLegend"
                      @changeIndex="changeIndex"
                      text
                      :type.sync="compareStatistics"
                      :legendMenu.sync="legendMenu"
                      :customLegend.sync="customLegend"
                      :width="'95%'"/>
                </div>
                <v-layout class="fill-height" v-if="compareStatistics.data">
                  <v-spacer/>
                  <v-btn @click="showLegend = !showLegend" height="40" width="40" small color="transparent">
                    <v-icon size="22" v-if="!showLegend">mdi-arrow-expand</v-icon>
                    <v-icon size="22" v-else>mdi-arrow-collapse</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
              <v-layout
                  style="height: 175px; font-size: 12px; overflow-y: auto; background-color: var(--v-primary-base);"
                  class="px-2 py-1" column
                  v-if="showLegend && compareStatistics.data">
                <v-layout style="max-height: 30px" align-center v-for="(item, i) in compareStatistics.data" :key="i">
                  <div
                      :style="{'background-color': item.color}"
                      style="width: 25px; height: 25px; "
                      class="mr-3">
                  </div>
                  <span style="font-size: 14px; width: 100px">{{ item.value }}</span>
                  <span style="font-size: 14px; width: 120px">{{ item.name }}</span>
                  <v-spacer/>
                  <div style="width: 100px" v-if="item.area">
                    <span style="font-size: 14px" v-html="item.area"></span>
                  </div>
                  <v-spacer/>
                </v-layout>
              </v-layout>
            </div>
            <div style="position: absolute; top: 15px; right: 55px; z-index: 2">
              <FieldInfor
                  ref="editField"
                  @saveField="(fieldInfo) => $refs.map.updateField(fieldInfo)"
                  @cancelEdit="cancelEditField"
              />
            </div>
            <Map
                ref="map"
                :isDraw.sync="drawing"
                :sync.sync="sync"
                :layerControl="!!currentField && group.id === 'dashboard'"
                :focusable="!!currentField"
                :type.sync="type"
                :currentField.sync="currentField"
                :isLoading.sync="loading"
                @resetMonth="months = []"
                @selectField="selectField"
                @displayTimeLine="displayTimeLine"
                @updateField="updateField"
                @saveField="addField"
                @goToDetail="(record) => $refs.recordDetail.openDialog(record)"/>
          </div>
          <div
              v-if="group.id === 'dashboard'"
              class="flex"
              style="flex: 2; background-color: var(--v-backgroundList-base); border-right: 2.5px solid var(--v-bar-base); min-height: 250px"
              :style="{'height': (currentField && !sync) ? '250px' : '45px', 'min-height': (currentField && !sync) ? '250px' : '0px', 'flex': (currentField && !sync) ? 2 : 'none'}"
          >
            <div style="height: 45px; background-color: var(--v-bar-base); min-width: 0; flex: none; width: 100%">
              <v-layout class="fill-height">
                <v-layout class="fill-height" style="flex: 1; border-bottom: 2px solid black" v-if="sync">
                  <v-slide-group
                      v-model="compare.month"
                      show-arrows
                  >
                    <v-slide-item
                        :value="month"
                        v-for="(month, index) in compare.months"
                        :key="index"
                        v-slot="{ active, toggle }"
                    >
                      <div
                          @click="selectCompareMonth(month)"
                          :style="{'background-color': compare.month === month ? 'var(--v-accent-base)' : ''}"
                          style="width: 130px; height: 45px; cursor: pointer; padding: 3px; position: relative"
                          class="px-2"
                          :key="index">
                        <v-layout class="fill-height" column align-center>
                          <v-layout style="flex: 2" class="fill-height" align-center>
                            <span style="font-size: 12px"
                                  :style="{'color': compare.month === month ? 'var(--v-secondary-base)' : ''}">
                              {{ month.date }}
                            </span>
                          </v-layout>
                          <v-layout style="flex: 1; height: 100%;" align-center justify-center
                                    v-if="month.field_cloud_cover >= 0">
                            <v-icon size="18" :color="compare.month === month ? 'var(--v-secondary-base)' : ''">
                              mdi-weather-cloudy
                            </v-icon>
                            <span
                                class="ml-2"
                                :style="{'color': compare.month === month  ? 'var(--v-secondary-base)' : ''}"
                                style="font-size: 12px">
                              {{ month.field_cloud_cover }} %
                            </span>
                          </v-layout>
                        </v-layout>
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </v-layout>
                <v-layout class="fill-height" style="flex: 1; border-bottom: 2px solid black">
                  <v-slide-group
                      v-show="!drawing"
                      v-model="currentMonth"
                      show-arrows
                  >
                    <v-slide-item
                        :value="month"
                        v-for="(month, index) in months"
                        :key="index"
                        v-slot="{ active, toggle }"
                    >
                      <div
                          @click="selectMonth(month)"
                          :style="{'background-color': currentMonth === month ? 'var(--v-accent-base)' : ''}"
                          style="width: 130px; height: 40px; cursor: pointer; padding: 3px; position: relative"
                          class="px-2"
                          :key="index">
                        <v-layout class="fill-height" column>
                          <v-layout style="flex: 2" class="fill-height" justify-center align-center>
                            <span
                                style="font-size: 12px"
                                :style="{'color': currentMonth === month ? 'var(--v-secondary-base)' : ''}">
                              {{ month.date }}
                            </span>
                          </v-layout>
                          <v-layout style="flex: 1; height: 100%;" align-center justify-center
                                    v-if="month.field_cloud_cover >= 0">
                            <v-icon size="18" :color="currentMonth === month ? 'var(--v-secondary-base)' : ''">
                              mdi-weather-cloudy
                            </v-icon>
                            <span
                                class="ml-2"
                                :style="{'color': currentMonth === month ? 'var(--v-secondary-base)' : ''}"
                                style="font-size: 12px">
                              {{ month.field_cloud_cover }} %
                            </span>
                          </v-layout>
                        </v-layout>
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </v-layout>
                <div style="flex: none; width: 50px; height: 100%; border-bottom: 2px solid black" v-if="!sync">
                  <v-layout class="fill-height" style="; border-left: 3px solid var(--v-accent-base)" align-center
                            justify-center>
                    <v-btn icon @click="startPlay" :disabled="!months.length || drawing" v-if="!autoPlay">
                      <v-icon size="30">mdi-play-circle</v-icon>
                    </v-btn>
                    <v-btn icon @click="stopPlay" :disabled="!months.length || drawing" v-else>
                      <v-icon size="30">mdi-pause-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
              </v-layout>
            </div>
            <v-layout class="fill-height" v-if="currentField" style="position: relative">
              <!--              <div style="width: 250px; height: 100%; flex: none; background-color: var(&#45;&#45;v-primary-base)" class="pa-4">-->
              <!--                <div style="width: 100%" class="mb-3">-->
              <!--                  <SelectIndex-->
              <!--                      right-->
              <!--                      @transformLegend="transformLegend"-->
              <!--                      @changeIndex="changeIndex"-->
              <!--                      outlined-->
              <!--                      :type.sync="type"-->
              <!--                      :legendMenu.sync="legendMenu"-->
              <!--                      :customLegend.sync="customLegend"-->
              <!--                      :width="'100%'"/>-->
              <!--                </div>-->
              <!--                <div style="width: 100%" class="my-3">-->
              <!--                  <DatePicker outlined dense left label="From date" v-model="fromDate"/>-->
              <!--                </div>-->
              <!--                <div style="width: 100%" class="my-3">-->
              <!--                  <DatePicker outlined dense left label="To date" v-model="toDate"/>-->
              <!--                </div>-->
              <!--                <div style="width: 100%" class="my-3">-->
              <!--                  <v-btn color="secondary" width="100%"-->
              <!--                         @click="() => $refs.fieldControl.getChartData(this.currentField)">-->
              <!--                    <v-icon class="mr-1" size="21">mdi-filter-outline</v-icon>-->
              <!--                    filter-->
              <!--                  </v-btn>-->
              <!--                </div>-->
              <!--              </div>-->
              <v-layout class="fill-height pt-1 pb-2" style="background-color: var(--v-backgroundList-base)">
                <div
                    v-if="isNoData" class="no-chart">
                  <v-layout class="fill-height" align-center justify-center>
                    no data for analysis
                  </v-layout>
                </div>
                <canvas style="height: 80%; width: 100%; flex: none" id="myChart"></canvas>
              </v-layout>
            </v-layout>
          </div>
        </v-layout>
      </v-layout>
    </v-layout>
    <SaveField ref="saveField" @saveField="saveField"/>
  </v-layout>
</template>

<script>
import Chart from "chart.js/auto"
import FieldControl from './FieldControl'
import Map from '@/components/Map'
import {mapState} from "@/store/ults"
import STATUS from "@/constants/record-status-constants"
import MonthPicker from "@/components/MonthPicker"
import SaveField from "@/components/home/dashboard/FieldControl/SaveField"
import DatePicker from "@/components/DatePicker"
import {createField, getFieldInfo, getLegend} from "@/backend"
import AreaTransform from "@/ultis/convertArea"
import SelectIndex from "@/components/SelectIndex"
import sleep from "@/ultis/sleep";
import FieldInfor from "@/components/home/dashboard/FieldControl/FieldInfor"
import IntroStep from "@/ultis/introStep"
import DateRangerPicker from "@/components/DateRangerPicker";
import AddField from "@/components/home/dashboard/addField/AddField";

export default {
  name: "Dashboard",
  components: {
    AddField,
    DateRangerPicker, FieldInfor, SelectIndex, DatePicker, SaveField, MonthPicker, Map, FieldControl
  },
  data() {
    return {
      addType: 'file',
      isAddField: false,
      tab: {
        name: 'Dashboard',
        closeAble: false,
        id: undefined
      },
      listField: [],
      firstLoad: true,
      indexMenu: false,
      legendMenu: false,
      chartData: [],
      myChart: undefined,
      labels: [],
      lineData: [],
      drawing: false,
      compare: {
        months: undefined,
        month: undefined
      },
      sync: false,
      showLegend: false,
      type: {},
      dates: [this.getBeforeDate(), this.getCurrentDate()],
      currentMonth: undefined,
      isNoData: true,
      months: [],
      autoPlay: undefined,
      currentField: undefined,
      listStatus: STATUS,
      loading: false,
      mapLegend: [],
      customLegend: [],
      compareStatistics: {}
    }
  },
  props: {},
  mounted() {
    this.getIndex()
    // this.startIntroduction()
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("legend", ["legend"]),
    ...mapState("main", ["menu", "group"]),
    ...mapState('tabs', ['tabs']),
    ...mapState('map', ['isDetect', 'detectFields']),
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6
      }
      if (this.$vuetify.breakpoint.lg) {
        return 4
      }
      if (this.$vuetify.breakpoint.md) {
        return 3
      }
      return 1
    }
  },
  watch: {
    isAddField(val) {
      this.detectFields = []
      if (!val) this.isDetect = false
      this.$refs.map.resetDraw()
    },
    currentField(val) {
      if (!this.mapLegend.length && !this.firstLoad) this.getIndex()
      if (!val) this.$refs.map.currentUid = undefined
      // this.changeLegend()
    },
    sync(val) {
      if (val) {
        this.stopPlay()
        this.selectCompareMonth(this.compare.months[0])
      }
    },
    months(val) {
      this.compare.months = val
      this.compare.month = val[0]
    },
    tab(val) {
      if (this.tabs[val].id) {
        this.$refs.fieldControl.selectField(this.tabs[val].id)
      } else if (this.currentField) this.$refs.fieldControl.backToListField()
    }
  },
  methods: {
    changeDate(status) {
      if (status) this.dates = [`01-01-${new Date().getFullYear() - 1}`, this.getCurrentDate()]
      else this.dates = [this.getBeforeDate(), this.getCurrentDate()]
    },
    openAddForm(type) {
      this.addType = type
      this.isAddField = true
    },
    cancelEditField() {
      if (this.months.length) this.selectMonth(this.months[0])
      this.$refs.map.cancelEdit()
    },
    async startIntroduction() {
      await this.addStep()
      this.intro.start()
    },
    async addStep() {
      await sleep(1000)
      let steps = IntroStep.getStepIntro()
      this.intro.setOptions({
        disableInteraction: true,
        overlayOpacity: 0.8,
        exitOnOverlayClick: false,
        showBullets: false,
        steps: steps
      }).oncomplete(() => {
      }).onexit(() => {
      })
    },
    setCurrentField(field) {
      this.currentField = field
    },
    changeIndex(index) {
      this.compareStatistics = index
      this.type = index
      this.indexMenu = false
      this.legendMenu = false
    },
    changeIndexToNDVI() {
      this.mapLegend.forEach(val => {
        val.indices.forEach(index => {
          if (index.code === 'NDVI') this.type = index
        })
      })
    },
    async getIndex() {
      try {
        const res = await getLegend()
        this.$store.commit('legend/SET_LEGEND', res.data)
        this.mapLegend = res.data
        res.data.forEach(val => {
          val.indices.forEach(index => {
            if (index.code === 'NDVI') this.type = index
          })
        })
        if (!this.type) this.type = res.data[0].indices[0]
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.changeLegend()
      }
    },
    changeLegend() {
      let val
      if (this.currentMonth && this.currentField) {
        for (let i = 0; i , this.mapLegend.length; i++) {
          val = this.mapLegend[i].indices ? this.mapLegend[i].indices.find(val => val.code === this.type.code) : undefined
          if (val) break
        }
        if (val.data) {
          val.data.forEach(index => {
            if (this.currentMonth.statistics) {
              let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === index.min_value)
              if (tmpIndex) index['area'] = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
        }
        if (this.type.data) this.type.data.forEach(val => {
          if (this.currentMonth.statistics) {
            let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === val.min_value)
            if (tmpIndex) val.area = AreaTransform.transformUnit(tmpIndex.area)
          }
        })
      } else {
        this.mapLegend.forEach(group => {
          group.indices.forEach(val => {
            if (val.data) {
              val.data.forEach(index => {
                index.area ? delete index.area : ''
              })
            }
          })
        })
        if (this.type.data) this.type.data.forEach(val => {
          delete val.area
        })
      }
    },
    destroyChart() {
      this.stopPlay()
      this.dates = [this.getBeforeDate(), this.getCurrentDate()]
      this.myChart ? this.myChart.destroy() : ''
      this.myChart = undefined
    },
    async selectField(fieldId) {
      try {
        this.loading = true
        let res = await getFieldInfo(fieldId)
        if (!this.tabs.some(val => val.id === res.data.id)) {
          this.$store.commit('tabs/ADD_TAB', {
            closAble: true,
            icon: 'mdi-vector-rectangle',
            name: res.data.name,
            id: res.data.id
          })
        }
        this.tab = this.tabs.findIndex(val => val.id === res.data.id)
        res.data.layerId = 'aoi_' + res.data.id
        res.data.featureCollection = {
          "type": "FeatureCollection",
          "name": "field",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": res.data.geometry
            }
          ]
        }
        await this.$refs.fieldControl.selectField(fieldId)
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    async updateField(data) {
      await this.$refs.fieldControl.updateField(data)
      await this.$refs.map.refreshMap()
    },
    removeAllMap() {
      this.$refs.map.removeAllImage()
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
    },
    getCurrentDate() {
      let currentDate = new Date()
      let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
      let year = currentDate.getFullYear()

      return year + '-' + month + '-' + '01'
    },
    getBeforeDate() {
      let beforeDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3)
      // let beforeDay = beforeDate.getDate() < 10 ? '0' + beforeDate.getDate() : beforeDate.getDate()
      // let beforeMonth = beforeDate.getMonth() < 10 ? '0' + (beforeDate.getMonth() + 1) : (beforeDate.getMonth() + 1)
      // let beforeYear = beforeDate.getFullYear()
      // let beforeDate = new Date()
      let beforeMonth = (beforeDate.getMonth() + 1) < 10 ? '0' + (beforeDate.getMonth() + 1) : (beforeDate.getMonth() + 1)
      let beforeYear = beforeDate.getFullYear()
      return beforeYear + '-' + beforeMonth + '-' + '01'
    },
    displayTimeLine(data) {
      this.months = data
      this.currentMonth = (this.currentMonth && this.months.some(month => month.date === this.currentMonth.date)) ? this.months.find(month => month.date === this.currentMonth.date) : data[0]
      this.changeLegend()
      this.selectMonth(this.currentMonth)
    },
    displayChartData(data) {
      this.$refs.map.removeAllImage()
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
      data.forEach(image => {
        image.geometry = this.currentField.geometry
      })
      this.$refs.map.addPolygonToMap({
        "type": "FeatureCollection",
        "name": this.currentField.id,
        "features": [
          {
            "type": "Feature",
            "properties": {},
            "geometry": this.currentField.geometry
          }]
      }, '#3592FD', 'aoi_' + this.currentField.id)
      this.$refs.map.addMultiImageToMap(data)
      if (!data[0] || !data[0].statistics) {
        this.months = []
        this.isNoData = true
        return
      }

      this.isNoData = false
      if (this.myChart) this.updateChart(data)
      else this.createChart(data)
    },
    updateChart(data) {
      this.displayTimeLine(data)
      this.myChart.data.labels = data.map(val => val.date)
      let lineData = {
        type: "line",
        label: "Average",
        data: data.map(val => val.average),
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        pointHoverRadius: 4,
        backgroundColor: "rgba(20,60,224,0.35)",
        borderColor: "rgb(4,31,225)",
        stack: "Average",
        yAxisID: 'y'
      }
      let datasets = []
      data.forEach(val => {
        if (val.statistics) val.statistics.forEach(index => {
          if (!datasets.some(el => el.label === index.label)) {
            datasets.push({
              label: index.label,
              data: [index.area],
              type: "bar",
              barPercentage: 0.8,
              backgroundColor: [
                index.color
              ],
              borderWidth: 1,
              stack: val.date,
              yAxisID: 'y1'
            })
          } else {
            datasets.find(data => data.label === index.label).data.push(index.area)
          }
        })
      })
      datasets.unshift(lineData)
      this.myChart.data.datasets = datasets
      this.myChart.update()
      this.myChart.resize()
    },
    createChart(data) {
      this.transformData(data)
      this.displayChart()
    },
    transformData(data) {
      this.displayTimeLine(data)
      this.labels = data.map(val => val.date)
      let lineData = {
        type: "line",
        label: "Average",
        data: data.map(val => val.average),
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        pointHoverRadius: 4,
        backgroundColor: "rgba(20,60,224,0.35)",
        borderColor: "rgb(4,31,225)",
        stack: "Average",
        yAxisID: 'y'
      }
      this.chartData = []
      data.forEach(val => {
        val.statistics.forEach(index => {
          if (!this.chartData.some(el => el.label === index.label)) {
            this.chartData.push({
              label: index.label,
              data: [index.area],
              type: "bar",
              barPercentage: 0.8,
              backgroundColor: [
                index.color
              ],
              borderWidth: 1,
              stack: val.date,
              yAxisID: 'y1'
            })
          } else {
            this.chartData.find(data => data.label === index.label).data.push(index.area)
          }
        })
      })
      this.chartData.unshift(lineData)
    },
    displayChart() {
      let ctx = document.getElementById('myChart')
      let data = {
        "labels": this.labels,
        "datasets": this.chartData
      }
      this.myChart = new Chart(ctx, {
        data: data,
        options: {
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: false,
              position: window.innerHeight > 350 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#1940e5',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              },
              ticks: {
                color: 'white',
                font: {
                  size: 11
                }
              },
            },
            y1: {
              title: {
                color: 'white',
                text: 'Area (Square meter)',
                display: true
              },
              stacked: true,
              grid: {
                display: false
              },
              ticks: {
                color: 'white',
                font: {
                  size: 11
                }
              },
            },
            y: {
              position: 'right',
              title: {
                text: '',
                display: false
              },
              stacked: true,
              grid: {
                color: 'white',
                display: false
              },
              ticks: {
                color: 'white',
                font: {
                  size: 11
                }
              },
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel
              }
            }
          }
        }
      })
    },
    selectCompareMonth(month) {
      this.compare.month = month
      let index = this.months.findIndex(val => val === this.compare.month)
      this.compareStatistics = JSON.parse(JSON.stringify(this.type))
      this.compareStatistics.data.forEach(val => {
        let tmpIndex = this.months[index].statistics.find(index => index.min_value === val.min_value)
        if (tmpIndex) val.area = AreaTransform.transformUnit(tmpIndex.area)
      })
      this.$refs.map.changeVisibilityCompare(this.months[index].id)
    },
    selectMonth(month) {
      this.currentMonth = month
      let index = this.months.findIndex(val => val === this.currentMonth)
      this.$refs.map.changeVisibility(this.months[index].id)
      this.changeLegend()
    },
    editField(field) {
      this.stopPlay()
      this.$refs.editField.openDialog(field)
      this.$refs.map.editLayer(field)
    },
    addField(featureCollection) {
      this.$refs.saveField.openSaveForm(featureCollection)
    },
    async saveField(data) {
      try {
        let isSelectField = data.isSelectField
        let isDetect = data.isDetect
        if (data.isSelectField) delete data.isSelectField
        this.loading = true
        let res = await createField(data)
        await this.$refs.fieldControl.searchField()
        this.$refs.map.cancel()
        res.data.layerId = 'aoi_' + res.data.id
        res.data.featureCollection = {
          "type": "FeatureCollection",
          "name": "field",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": res.data.geometry
            }
          ]
        }
        this.loading = false
        if (!isSelectField) this.$refs.fieldControl.goToField(res.data, isDetect)
        // await this.$refs.fieldControl.selectField(res.data.id)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.loading = false
      } finally {
      }
      // this.$refs.fieldControl.addField(data)
    },
    openDrawTool() {
      this.drawing = true
      this.$nextTick(() => {
        this.$refs.map.changeMode('polygon')
        this.$refs.map.reSize()
      })
    },
    download(type) {
      switch (type) {
        case 'contours':
          let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.currentField.featureCollection));
          let downloadAnchorNode = document.createElement('a')
          downloadAnchorNode.setAttribute("href", dataStr)
          downloadAnchorNode.setAttribute("download", this.currentField.name + ".geojson")
          document.body.appendChild(downloadAnchorNode); // required for firefox
          downloadAnchorNode.click()
          downloadAnchorNode.remove()
          break
        case 'tif':
          window.open(this.currentMonth.download_url, '_blank');
          break
        case 'csv':
          let string = 'Date, '
          this.type.data.forEach((val, ind) => {
            string = string + val.name
            if (ind < this.type.data.length - 1) string = string + ', '
            else string = string + ' \n'
          })
          this.months.forEach(month => {
            string = string + month.date + ', '
            this.type.data.forEach((val, index) => {
              let value = month.statistics.find(item => item.min_value === val.min_value)
              string = string + value.area
              if (index < this.type.data.length - 1) string = string + ', '
              else string = string + ' \n'
            })
          })
          let strData = 'data:text/json;charset=utf-8,' + encodeURIComponent(string)
          let downloadNode = document.createElement('a')
          downloadNode.setAttribute('href', strData)
          downloadNode.setAttribute('download', this.currentField.name + '_' + this.type.name + '.csv')
          document.body.appendChild(downloadNode) // required for firefox
          downloadNode.click()
          downloadNode.remove()
          break
        default:
          return
      }
    },
    startPlay() {
      let index = this.months.findIndex(val => val === this.currentMonth)
      this.autoPlay = setTimeout(() => {
        if (index + 1 < this.months.length) {
          this.selectMonth(this.months[index + 1])
          this.startPlay()
        } else {
          this.stopPlay()
        }
      }, 1000 * 3)
    },
    stopPlay() {
      if (this.autoPlay) clearInterval(this.autoPlay)
      this.autoPlay = undefined
    }
  }
}
</script>

<style scoped>

/deep/
.custom-tab .v-slide-group__prev {
  height: 34px !important;
}

/deep/
.custom-tab .v-slide-group__next {
  height: 34px !important;
}

.no-chart {
  background-color: #181F26;
  font-size: 36px;
  font-weight: bold;
  color: #777c85;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0px;
  height: 200px;
  width: calc(100vw - 350px);
  text-align: center
}

/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}

/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}

/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}

/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}

/deep/
.custom-select .v-select__selection {
  color: var(--v-secondary-base) !important;
  text-transform: uppercase;
  font-weight: 500;
}

/deep/
.select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
}

/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}

#legend-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: none;
  border: 1px solid lightgray;
}

.header-monitor {
  flex: none;
  height: 48px;
  width: 100%;
  background-color: var(--v-bar-base);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}

.custom-legend {
  flex: none;
  height: 140px
}

@media only screen and (max-height: 750px) {
  .custom-legend {
    flex: none;
    height: 60px
  }
}

.report-status-small {
  display: none;
}

.report-status {
  display: flex;
}

@media only screen and (max-width: 1420px) {
  .report-status-small {
    display: block;
  }

  .report-status {
    display: none;
  }
}

.map-legend {
  min-width: 50px;
  border-radius: 8px;
  position: absolute;
  top: 15px;
  right: 60px;
  z-index: 2
}

.split-map-legend {
  border: 1px solid #5d5d5d;
  min-width: 50px;
  height: 40px;
  background-color: var(--v-primary-base);
  border-radius: 4px;
  position: absolute;
  top: 15px;
  left: 265px;
  z-index: 2
}
</style>
